<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-50 bg-black"
  >
    <div class="modal">
      <form>
        <div class="flex flex-col w-full mt-2">
          <input-label for="name" value="Nombre"></input-label>
          <input class="w-full" id="name" type="text" v-model="form.name" />
          <validation-errors :errors="errorFor('name')"></validation-errors>
        </div>

        <primary-button
          class="mt-12 w-full"
          :loading="loading"
          @buttonClicked="save"
          >{{ !isEditing ? "Crear" : "Editar" }} Manzana</primary-button
        >
      </form>

      <button class="link mt-4 text-center" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
export default {
  name: "Block Form",

  data() {
    return {
      form: {
        name: null,
        projectId: null,
      },
      loading: false,
      isEditing: false,
    };
  },

  props: {
    projectId: Number,
    block: Object,
  },

  mixins: [validationErrors],

  created() {
    if (this.block) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.block));
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    validateForm() {
      this.errors = {};

      if (!this.form.name) {
        this.errors["name"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      if (!this.isEditing) {
        this.createBlock();
      } else {
        this.updateBlock();
      }
    },

    async createBlock() {
      this.form.projectId = this.projectId;

      this.loading = true;

      try {
        const response = await api.blocks.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async updateBlock() {
      this.loading = true;

      try {
        const response = await api.blocks.update(this.block.id, this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
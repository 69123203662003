<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-50 bg-black"
  >
    <div class="modal">
      <form>
        <div class="flex flex-col w-full mt-2">
          <input-label for="type" value="Tipo" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="type"
            v-model="form.type"
            :disabled="isEditing"
          >
            <option value="text" selected>Texto</option>
            <option value="image">Imagen</option>
            <option value="video">Video</option>
          </select>
          <validation-errors :errors="errorFor('')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="title" value="Título"></input-label>
          <input class="w-full" id="title" type="text" v-model="form.title" />
          <validation-errors :errors="errorFor('title')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="title" value="Contenido"></input-label>
          <textarea
            class="h-24 w-full"
            id="title"
            v-model="form.content"
          ></textarea>
          <!-- <input class="w-full" id="title" type="text" v-model="form.title" /> -->
          <validation-errors :errors="errorFor('content')"></validation-errors>
        </div>

        <div v-if="form.type === 'image' && !isEditing" class="flex items-center mt-4">
          <div v-if="!imagePreview" class="w-full">
            <input
              class="hidden"
              type="file"
              name="image"
              id="image"
              ref="image"
              accept="image/*"
              @change="getImage"
            />

            <label for="image" class="cursor-pointer secondary-button"
              >+ Agregar Imagen</label
            >

            <validation-errors
              class="mt-2 block"
              :errors="errorFor('image')"
            ></validation-errors>
          </div>

          <div v-else class="flex items-center">
            <img
              ref="imagePreview"
              class="rounded-lg object-cover object-center mr-4"
              :class="[{ 'animate-pulse': loading }]"
              :style="{
                height: '100px',
              }"
              :src="imagePreview"
            />
            <button
              v-if="!loading"
              class="button-interactive delete-hover"
              @click.prevent="
                imagePreview = null;
                newImage = null;
              "
            >
              <close-icon class="w-4 fill-current" />
            </button>
          </div>
        </div>

        <div
          v-else-if="form.type === 'video'"
          class="flex flex-col w-full mt-2"
        >
          <input-label for="src" value="URL de video"></input-label>
          <input class="w-full" id="src" type="text" v-model="form.src" />
          <validation-errors :errors="errorFor('src')"></validation-errors>
        </div>

        <primary-button
          class="mt-12 w-full"
          :loading="loading"
          @buttonClicked="save"
          >{{ !isEditing ? "Crear" : "Editar" }} Publicación</primary-button
        >
      </form>

      <button class="link mt-4 text-center" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
export default {
  name: "Post Form",

  data() {
    return {
      form: {
        title: "",
        content: "",
        projectId: null,
        type: "text",
        src: "",
      },
      loading: false,
      isEditing: false,
      imagePreview: null,
      newImage: null,
    };
  },

  props: {
    projectId: Number,
    post: Object,
  },

  mixins: [validationErrors],

  created() {
    if (this.post) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.post));
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getImage(e) {
      let image = e.target.files[0];
      if (image) {
        let url = URL.createObjectURL(image);
        let img = new Image();

        img.onload = () => {
          URL.revokeObjectURL(url);
          let reader = new FileReader();
          reader.onload = (e) => {
            this.newImage = image;
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(image);
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          alert("Unable to load image");
        };

        img.src = url;
      }
    },

    validateForm() {
      this.errors = {};

      if (!this.form.title) {
        this.errors["title"] = ["Campo requerido"];
      }

      if (this.form.type === "image" && !this.newImage && !this.isEditing) {
        this.errors["image"] = ["Es necesario agregar una imagen"];
      }

      if (this.form.type === "video" && !this.form.src) {
        this.errors["src"] = ["Es necesario agregar un video"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      if (!this.isEditing) {
        this.createPost();
      } else {
        this.updatePost();
      }
    },

    async createPost() {
      this.form.projectId = this.projectId;

      this.loading = true;

      if (this.form.type !== "image") {
        try {
          const response = await api.posts.create(this.form);
          this.$store.dispatch("successSnack", response.message);
          this.$emit("confirm");
          this.close();
        } catch (error) {
          if (is422(error)) {
            this.errors = error?.response?.data?.errors;
          }
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else {
        const formData = new FormData();

        formData.append("type", this.form.type);
        formData.append("title", this.form.title);
        formData.append("content", this.form.content);
        formData.append("projectId", this.projectId);
        formData.append("image", this.newImage);

        try {
          const response = await api.posts.createWithImage(formData);
          this.$store.dispatch("successSnack", response.message);
          this.$emit("confirm");
          this.close();
        } catch (error) {
          if (is422(error)) {
            this.errors = error?.response?.data?.errors;
          }
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },

    async updatePost() {
      this.loading = true;

      try {
        const response = await api.posts.update(this.post.id, this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
<template>
  <div class="w-full h-full flex flex-col relative">
    <nav
      v-if="user"
      class="flex justify-between px-6 py-4 bg-content-800 fixed z-10 left-0 right-0 top-0"
    >
      <router-link :to="{ name: 'investments' }" class="button-interactive">
        <img class="w-24" :src="require('@/assets/logo.png')" alt="" />
      </router-link>

      <div class="flex items-center space-x-4">
        <!-- <button class="rounded-full w-6 text-white button-interactive">
          <bell-icon class="w-full stroke-current" />
        </button> -->

        <div class="flex flex-col">
          <p class="text-xs text-right">
            {{ user.email }}
          </p>
          <div class="w-full flex justify-end mt-1">
            <button
              class="button-interactive text-xs w-fit"
              @click="
                $store.dispatch('logout');
                $router.push({ name: 'landing' });
              "
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="user" class="flex space-x-4 p-4 w-full h-full pt-24">
      <div
        class="flex flex-col flex-shrink-0 w-[50px] sm:w-[180px] h-full rounded-lg text-white sm:px-8 py-8 border border-primary-500 smooth"
      >
        <router-link
          :to="{ name: 'investments' }"
          class="button-interactive text-xs font-light"
          :class="[{ 'text-primary-500': selectedTab === 'investments' }]"
        >
          <div class="flex items-center">
            <investments-icon
              class="w-4 fill-current sm:mr-2 mx-auto sm:mx-0"
            />
            <span class="hidden sm:block flex-shrink-0">Inversiones</span>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'projects' }"
          class="button-interactive text-xs font-light mt-6"
          :class="[{ 'text-primary-500': selectedTab === 'projects' }]"
        >
          <div class="flex items-center">
            <projects-icon class="w-4 stroke-current sm:mr-2 mx-auto sm:mx-0" />
            <span class="hidden sm:block flex-shrink-0">Proyectos</span>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'opportunities' }"
          class="button-interactive text-xs font-light mt-6"
          :class="[{ 'text-primary-500': selectedTab === 'opportunities' }]"
        >
          <div class="flex items-center">
            <opportunities-icon
              class="w-4 stroke-current sm:mr-2 mx-auto sm:mx-0"
            />
            <span class="hidden sm:block flex-shrink-0">Oportunidades</span>
          </div>
        </router-link>
      </div>

      <router-view class="flex-auto overflow-auto" />
    </div>
  </div>
</template>

<script>
import BellIcon from "../../../icons/BellIcon";
import AccountIcon from "../../../icons/AccountIcon";
import InvestmentsIcon from "../../../icons/InvestmentsIcon";
import OpportunitiesIcon from "../../../icons/OpportunitiesIcon";
import ProjectsIcon from "../../../icons/ProjectsIcon";
import FinancialAdviceIcon from "../../../icons/FinancialAdviceIcon";
import { mapState } from "vuex";
export default {
  name: "Dashboard",

  components: {
    BellIcon,
    AccountIcon,
    InvestmentsIcon,
    OpportunitiesIcon,
    ProjectsIcon,
    FinancialAdviceIcon,
  },

  computed: {
    ...mapState({
      selectedTab: "selectedTab",
      user: "user",
    }),
  },
};
</script>
<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-25 bg-black"
  >
    <div class="modal relative text-center">
      <div>¿Estás seguro que deseas eliminar este elemento?</div>

      <primary-button
        class="w-full mt-4 delete-hover"
        :loading="loading"
        @buttonClicked="confirmDelete"
        >Eliminar
      </primary-button>

      <button class="link mt-4 mx-auto w-full" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    confirmDelete() {
      this.$emit("confirmDelete");
    },
  },
};
</script>
<template>
  <div>
    <transition name="modal">
      <delete
        v-if="showDeleteModal"
        :loading="loadingDelete"
        @close="close"
        @confirmDelete="deleteElement"
      ></delete>
    </transition>

    <transition name="modal">
      <payment-form
        v-if="showPaymentForm"
        :stagedInvestmentId="investment.id"
        :payment="paymentToUpdate"
        @close="showPaymentForm = false"
        @confirm="loadInvestment"
      ></payment-form>
    </transition>

    <div class="flex items-center mb-6">
      <router-link
        :to="{ name: 'adminInvestmentsIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <div v-if="!loading">
      <div v-if="investment">
        <router-link
          :to="{
            name: 'adminClientsDetails',
            params: { id: investment.client.id },
          }"
          class="button-interactive relative text-white font-thin text-3xl"
          >{{ investment.client.name }} {{ investment.client.lastName }}
          <div class="absolute rounded h-[1px] bg-white -bottom-1 w-full"></div
        ></router-link>

        <d-b-flag
          class="mt-4"
          field="active"
          :value="investment.active"
          title="Activa"
          :id="investment.id"
          model="stagedInvestment"
          :resource="{ api: 'stagedInvestments', name: 'updateActive' }"
          @update="investment.active = $event"
        />

        <d-b-string
          class="mt-4"
          field="name"
          :value="`${investment.name}`"
          title="Nombre"
          :id="investment.id"
          model="stagedInvestment"
          :resource="{ api: 'stagedInvestments', name: 'updateName' }"
          :active="true"
          @updateName="investment.name = $event"
        />

        <d-b-string
          class="mt-4"
          field="timeLapse"
          :value="`${investment.timeLapse}`"
          title="Período de tiempo (meses)"
          :id="investment.id"
          model="stagedInvestment"
          :resource="{ api: 'stagedInvestments', name: 'updateTimeLapse' }"
          :active="true"
          @updateTimeLapse="investment.namtimeLapse = $event"
        />

        <d-b-string
          class="mt-4"
          field="initialAmountInvested"
          :value="`${investment.initialAmountInvested}`"
          title="Monto de inversión"
          :id="investment.id"
          model="stagedInvestment"
          :resource="{
            api: 'stagedInvestments',
            name: 'updateInitialAmountInvested',
          }"
          :active="true"
          type="number"
          @updateInitialAmountInvested="
            investment.initialAmountInvested = $event
          "
        />

        <d-b-datetime
          class="mt-4"
          field="purchaseDate"
          :value="investment.purchaseDate"
          title="Fecha de inversión"
          :id="investment.id"
          :resource="{ api: 'stagedInvestments', name: 'updatePurchaseDate' }"
          @updatePurchaseDate="investment.purchaseDate = $event"
        />

        <d-b-string
          class="mt-4"
          field="investmentReturn"
          :value="`${investment.investmentReturn}`"
          title="Retorno de inversión %"
          :id="investment.id"
          model="stagedInvestment"
          :resource="{
            api: 'stagedInvestments',
            name: 'updateInvestmentReturn',
          }"
          :active="true"
          type="number"
          @updateInvestmentReturn="investment.investmentReturn = $event"
        />

        <d-b-string
          class="mt-4"
          field="totalReleased"
          :value="`${investment.totalReleased}`"
          title="Inversión total liberada"
          :id="investment.id"
          model="stagedInvestment"
          :resource="{
            api: 'stagedInvestments',
            name: 'updateTotalReleased',
          }"
          :active="true"
          type="number"
          @updateTotalReleased="investment.totalReleased = $event"
        />

        <div
          class="mt-8 flex flex-col bg-gradient-to-r from-content-800 to-content-700 w-full p-4 rounded-lg mb-8"
        >
          <div class="my-6 flex flex-col">
            <h1 class="text-success-500 font-light text-lg sm:text-3xl mx-auto">
              {{ getEstimatedValue(investment) }}
            </h1>
            <p class="mx-auto font-light text-sm sm:text-base">
              Monto de retorno estimado
            </p>
          </div>

          <div class="mt-6 relative">
            <div
              class="absolute inset-0 text-center items-center justify-center flex flex-col"
            >
              <p class="text-success-500">
                {{ getMonthsPassed(investment) }}
              </p>
              <p>de</p>
              <p>{{ investment.timeLapse }} meses</p>
            </div>
            <DoughnutChart
              :chartData="prepareDoughnutChartData(investment)"
              :options="doughnutChartOptions"
            />
          </div>

          <h2 class="mt-8">Pagos</h2>

          <button class="secondary-button mt-4" @click="showPaymentForm = true">
            + Agregar Pago
          </button>

          <div class="flex flex-wrap">
            <div
              v-for="payment in investment.payments"
              :key="payment.id"
              class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
            >
              <div class="flex space-x-2 items-center">
                <p class="font-semibold text-primary-500">
                  {{ integerToCurrency(payment.amount) }}
                </p>

                <button
                  class="button-interactive"
                  @click="
                    paymentToUpdate = payment;
                    showPaymentForm = true;
                  "
                >
                  <edit-icon class="fill-current w-4" />
                </button>

                <button
                  class="button-interactive delete-hover"
                  @click="
                    paymentToDelete = payment;
                    openDeleteModal();
                  "
                >
                  <trashcan-icon class="fill-current w-4" />
                </button>
              </div>

              <p class="text-sm text-content-200 text-semibold font-light my-2">
                {{ payment.name }}
              </p>
              <p class="text-xs text-content-200 text-semibold font-light">
                {{ formatDate(payment.paymentDate) }}
              </p>
            </div>
          </div>
        </div>

        <button
          class="mt-6 delete-button"
          @click="
            deleteInvestment = true;
            openDeleteModal();
          "
        >
          Eliminar Inversión
        </button>
      </div>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import InvestmentValueForm from "../../../../../components/modals/forms/InvestmentValue";
import PaymentForm from "../../../../../components/modals/forms/Payment";
import api from "../../../../../api";
import DBString from "../../../../../components/db-resources/String";
import DBFlag from "../../../../../components/db-resources/Flag";
import DBDatetime from "../../../../../components/db-resources/Datetime";
import validationErrors from "../../../../../mixins/validationErrors";
import deleteMixin from "../../../../../mixins/deleteMixin";
import { is422 } from "../../../../../utils/response";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  name: "Staged Investments Detail",

  components: {
    InvestmentValueForm,
    PaymentForm,
    DBString,
    DBFlag,
    DBDatetime,
    DoughnutChart,
  },

  data() {
    return {
      showInvestmentValue: false,
      showPaymentForm: false,
      investment: null,
      paymentToUpdate: null,
      paymentToDelete: null,
      deleteInvestment: false,
      loading: false,
    };
  },

  computed: {
    doughnutChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        cutout: "70%",
      };
    },
  },

  mixins: [validationErrors, deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "adminInvestments");

    this.loadInvestment();
  },

  methods: {
    async loadInvestment() {
      try {
        this.investment = await api.stagedInvestments.getById(
          this.$route.params.id
        );
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    validateForm() {
      this.errors = {};

      if (!this.form.clientId) {
        this.errors["client"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    async createInvestment() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      this.form["weddingId"] = this.$route.params.id;

      try {
        const response = await api.stagedInvestments.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "adminInvestmentsIndex" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    integerToCurrency(amount) {
      if (!amount) {
        return "-";
      }

      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    getCurrentValue(investment) {
      return this.integerToCurrency(
        investment.investmentValues[investment.investmentValues.length - 1]
          ? investment.investmentValues[investment.investmentValues.length - 1]
              .value
          : null
      );
    },

    getEstimatedValue(investment) {
      return this.integerToCurrency(
        investment.initialAmountInvested *
          (investment.investmentReturn / 100 + 1)
      );
    },

    getTotalPaid(payments) {
      return payments.reduce((sum, payment) => sum + payment.amount, 0);
    },

    getMonthsPassed(investment) {
      const purchaseDate = new Date(investment.purchaseDate);
      const currentDate = new Date();

      return (
        (currentDate.getFullYear() - purchaseDate.getFullYear()) * 12 +
        currentDate.getMonth() -
        purchaseDate.getMonth()
      );
    },

    prepareDoughnutChartData(investment) {
      const monthsPassed = this.getMonthsPassed(investment);

      const remainingMonths = Math.max(0, investment.timeLapse - monthsPassed);

      const adjustedMonthsPassed = Math.min(monthsPassed, investment.timeLapse);

      return {
        labels: ["Transcurrido", "Restante"],
        datasets: [
          {
            data: [adjustedMonthsPassed, remainingMonths],
            backgroundColor: ["#2feaa8", "#0b0d0e"],
            borderWidth: 0,
          },
        ],
      };
    },

    close() {
      this.paymentToDelete = null;
      this.deleteInvestment = false;
      this.closeDeleteModal();
    },

    async deleteElement() {
      this.loadingDelete = true;

      if (this.paymentToDelete) {
        try {
          const response = await api.payments.delete(this.paymentToDelete.id);
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.paymentToDelete = null;
          this.loadInvestment();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.deleteInvestment) {
        try {
          const response = await api.stagedInvestments.delete(
            this.investment.id
          );
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.deleteProject = null;
          this.$router.push({ name: "adminInvestmentsIndex" });
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loadingDelete = false;
    },
  },
};
</script>
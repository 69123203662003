<template>
  <div class="flex flex-col">
    <div class="flex space-x-8 mx-auto mb-6">
      <button
        class="relative text-white text-sm sm:text-base"
        v-for="type in investmentType"
        :key="type.id"
        @click="selectedType = type.id"
      >
        {{ type.display }}
        <transition name="expand">
          <div
            v-show="selectedType === type.id"
            class="absolute -bottom-2 w-full h-[2px] bg-primary-500 rounded"
          ></div>
        </transition>
      </button>
    </div>

    <router-link
      class="primary-button"
      :to="{ name: selectedType === 1 ? 'adminInvestmentsPropertyForm' : 'adminInvestmentsStagedForm' }"
    >
      Agregar Inversión
    </router-link>

    <div v-if="selectedType === 1">
      <table
        v-if="propertyInvestments"
        class="w-full table-auto text-xs sm:text-sm rounded-lg shadow-primary mt-6"
      >
        <thead class="text-left">
          <tr class="text-left">
            <th class="p-2">Id</th>
            <th class="p-2">Cliente</th>
            <th class="p-2">Lote</th>
            <th class="p-2">Projecto</th>
            <th class="p-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="investment in propertyInvestments"
            :key="investment.id"
            class="text-white"
          >
            <td class="p-2 border-t">{{ investment.id }}</td>
            <td class="p-2 border-t">{{ investment.client.name }} {{ investment.client.lastName }}</td>
            <td class="p-2 border-t">{{ investment.property.name }}</td>
            <td class="p-2 border-t">
              {{ investment.property.block.project.name }}
            </td>
            <td class="p-2 border-t">
              <router-link
                class="secondary-button"
                :to="{ name: 'adminInvestmentsPropertyDetails', params: { id: investment.id } }"
              >
                Detalle
                <!-- <details-icon class="w-5 fill-current stroke-current" /> -->
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else-if="selectedType === 2">
      <table
        v-if="stagedInvestments"
        class="w-full table-auto text-sm rounded-lg shadow-primary mt-6"
      >
        <thead class="text-left">
          <tr class="text-left">
            <th class="p-2">Id</th>
            <th class="p-2">Cliente</th>
            <th class="p-2">Nombre</th>
            <th class="p-2">Plazo en meses</th>
            <th class="p-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="investment in stagedInvestments"
            :key="investment.id"
            class="text-white"
          >
            <td class="p-2 border-t">{{ investment.id }}</td>
            <td class="p-2 border-t">{{ investment.client.name }} {{ investment.client.lastName }}</td>
            <td class="p-2 border-t">
              {{ investment.name }}
            </td>
            <td class="p-2 border-t">
              {{ investment.timeLapse }}
            </td>
            <td class="p-2 border-t">
              <router-link
                class="secondary-button"
                :to="{ name: 'adminInvestmentsStagedDetails', params: { id: investment.id } }"
              >
                Detalle
                <!-- <details-icon class="w-5 fill-current stroke-current" /> -->
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from "../../../../api";
export default {
  name: "Investments Index",

  data() {
    return {
      propertyInvestments: null,
      stagedInvestments: null,
      selectedType: 1,
      investmentType: [
        {
          id: 1,
          slug: "property",
          display: "Inmobiliarias",
        },
        {
          id: 2,
          slug: "staged",
          display: "Por rendimiento",
        },
      ],
    };
  },

  created() {
    this.$store.commit("setSelectedTab", "adminInvestments");

    this.loadPropertyInvestments();
    this.loadStagedInvestments();
  },

  methods: {
    async loadPropertyInvestments() {
      try {
        this.propertyInvestments = await api.propertyInvestments.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async loadStagedInvestments() {
      try {
        this.stagedInvestments = await api.stagedInvestments.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.2s ease-in-out;
}
.expand-enter-from, .expand-leave-to /* .expand-leave-active in <2.1.8 */ {
  width: 0;
  left: 50%;
}
.expand-enter-to, .expand-leave-from /* .expand-leave-active in <2.1.8 */ {
  width: 100%;
  left: 0;
}
</style>
export default {
  path: '/clients',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getAll() {
    try {
      const response = await this.apiClient.get(`${this.path}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getById(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateLastName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/last-name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updatePhone(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/phone`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateEmail(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/email`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updatePassword(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/password`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}
<template>
  <div class="flex flex-col">
    <router-link class="primary-button" :to="{ name: 'adminClientsForm' }">
      Agregar Cliente
    </router-link>

    <table
      v-if="clients"
      class="w-full table-auto text-xs sm:text-sm rounded-lg shadow-primary mt-8"
    >
      <thead class="text-left">
        <tr class="text-left">
          <th class="p-2">Id</th>
          <th class="p-2">Nombre(s)</th>
          <th class="p-2">Apellido(s)</th>
          <th class="p-2">Correo</th>
          <th class="p-2">Teléfono</th>
          <th class="p-2">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.id" class="text-white">
          <td class="p-2 border-t">{{ client.id }}</td>
          <td class="p-2 border-t">{{ client.name }}</td>
          <td class="p-2 border-t">{{ client.lastName }}</td>
          <td class="p-2 border-t">{{ client.user.email }}</td>
          <td class="p-2 border-t">{{ client.phone }}</td>
          <td class="p-2 border-t">
            <router-link
              class="secondary-button"
              :to="{ name: 'adminClientsDetails', params: { id: client.id } }"
            >
              Detalle
              <!-- <details-icon class="w-5 fill-current stroke-current" /> -->
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from "../../../../api";
export default {
  name: "Clients Index",

  data() {
    return {
      clients: null,
    };
  },

  created() {
    this.$store.commit("setSelectedTab", "adminClients");

    this.loadClients();
  },

  methods: {
    async loadClients() {
      try {
        this.clients = await api.clients.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },
  },
};
</script>
<template>
  <div
    class="flex flex-col justify-center items-center fixed z-50 inset-0 bg-opacity-50 bg-black"
  >
    <form class="modal relative">
      <div class="flex flex-col w-full">
        <label class="text-white" for="email">Correo</label>
        <input
          id="email"
          class="w-full text-white"
          v-model="form.email"
          type="text"
        />
        <validation-errors :errors="errorFor('email')"></validation-errors>
      </div>

      <div class="flex flex-col w-full mt-4">
        <label class="text-white" for="password">Contraseña</label>
        <input
          id="password"
          class="w-full text-white"
          v-model="form.password"
          type="password"
        />
        <validation-errors :errors="errorFor('password')"></validation-errors>
      </div>

      <primary-button
        class="mt-6 w-full"
        :loading="loading"
        @click.prevent="login"
      >
        Iniciar Sesión
      </primary-button>

      <button
        v-if="!loading"
        class="link mt-4 mx-auto text-white"
        @click.prevent="close"
      >
        Cancelar
      </button>
    </form>
  </div>
</template>

<script>
import api from "../../api";
import validationErrors from "../../mixins/validationErrors";
import { is422 } from "../../utils/response";
export default {
  name: "Login",

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  methods: {
    close() {
      this.$emit("close");
    },

    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);
      this.validateLoginPassword(this.form.password);

      return Object.keys(this.errors).length === 0;
    },

    async login() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.auth.login(this.form);
        this.$store.dispatch("login", response);
        if (response.user.type === 'admin') {
          this.$router.push({ name: "adminClientsIndex" });
        } else {
          this.$router.push({ name: "investments" });
        }
      } catch (error) {
        if (is422(error)) {
          this.errors = error.response && error.response.data.errors;
        } else {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },
  },
};
</script>
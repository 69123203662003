export default {
  path: '/blocks',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getAll() {
    try {
      const response = await this.apiClient.get(`${this.path}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getProperties(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}/properties`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getAvailableProperties(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}/available-properties`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getById(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async update(id, form) {
    try {
      const response = await this.apiClient.put(`${this.path}/${id}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}
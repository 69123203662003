<template>
  <div>
    <div class="flex items-center">
      <router-link
        :to="{ name: 'adminProjectsIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <form class="">
      <div class="flex flex-wrap">
        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="name" value="Nombre"></input-label>
          <input class="" id="name" type="text" v-model="form.name" />
          <validation-errors :errors="errorFor('name')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="address" value="Dirección"></input-label>
          <input class="" id="address" type="text" v-model="form.address" />
          <validation-errors :errors="errorFor('address')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="mapUrl" value="URL del mapa"></input-label>
          <input class="" id="mapUrl" type="text" v-model="form.mapUrl" />
          <validation-errors :errors="errorFor('mapUrl')"></validation-errors>
        </div>
      </div>

      <div class="flex items-center mt-4">
        <div v-if="!imagePreview" class="w-full">
          <input
            class="hidden"
            type="file"
            name="image"
            id="image"
            ref="image"
            accept="image/*"
            @change="getImage"
          />

          <label for="image" class="cursor-pointer secondary-button"
            >+ Agregar Imagen</label
          >
        </div>

        <div v-else class="flex items-center">
          <img
            ref="imagePreview"
            class="rounded-lg object-cover object-center mr-4"
            :class="[{ 'animate-pulse': loading }]"
            :style="{
              height: '100px',
            }"
            :src="imagePreview"
          />
          <button
            v-if="!loading"
            class="button-interactive delete-hover"
            @click.prevent="
              imagePreview = null;
              newImage = null;
            "
          >
            <close-icon class="w-4 fill-current" />
          </button>
        </div>
      </div>
      <div class="flex mt-2 w-full">
        <validation-errors
          :errors="errorFor('image')"
        ></validation-errors>
      </div>

      <primary-button
        class="mt-12 w-full sm:w-96"
        :loading="loading"
        @buttonClicked="createProject"
        >Crear Proyecto</primary-button
      >
    </form>
  </div>
</template>

<script>
import PasswordValidation from "../../../../components/ui/PasswordValidation";
import api from "../../../../api";
import validationErrors from "../../../../mixins/validationErrors";
import { is422 } from "../../../../utils/response";
export default {
  name: "Project Form",

  components: {
    PasswordValidation,
  },

  data() {
    return {
      form: {
        name: "",
        address: "",
        mapUrl: "",
      },
      loading: false,
      imagePreview: null,
      newImage: null,
    };
  },

  mixins: [validationErrors],

  created() {
    this.$store.commit("setSelectedTab", "adminProjects");
  },

  methods: {
    getImage(e) {
      let image = e.target.files[0];
      if (image) {
        let url = URL.createObjectURL(image);
        let img = new Image();

        img.onload = () => {
          URL.revokeObjectURL(url);
          let reader = new FileReader();
          reader.onload = (e) => {
            this.newImage = image;
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(image);
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          alert("Unable to load image");
        };

        img.src = url;
      }
    },

    validateForm() {
      this.errors = {};

      this.validateName(this.form.name);

      if (!this.newImage) {
        this.errors["image"] = ["Debes agregar una imagen."];
      }

      return Object.keys(this.errors).length === 0;
    },

    async createProject() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      const formData = new FormData();

      formData.append("name", this.form.name);
      formData.append("address", this.form.address);
      formData.append("mapUrl", this.form.mapUrl);
      formData.append("image", this.newImage);

      try {
        const response = await api.projects.create(formData);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "adminProjectsIndex" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
<template>
  <div>
    <div class="flex items-center">
      <router-link
        :to="{ name: 'adminInvestmentsIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <form class="">
      <div class="flex flex-wrap">
        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="client" value="Cliente" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="client"
            v-model="form.clientId"
          >
            <option :value="null" selected>Seleccionar</option>
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
            >
              {{ client.name }} {{ client.lastName }}
            </option>
          </select>
          <validation-errors :errors="errorFor('client')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="project" value="Projecto" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="project"
            v-model="projectId"
          >
            <option :value="null" selected>Seleccionar</option>
            <option
              v-for="project in projects"
              :key="project.id"
              :value="project.id"
            >
              {{ project.name }}
            </option>
          </select>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="block" value="Manzana" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="block"
            v-model="blockId"
            :disabled="blocks === null"
          >
            <option v-if="blocks" :value="null" selected>Seleccionar</option>
            <option v-for="block in blocks" :key="block.id" :value="block.id">
              {{ block.name }}
            </option>
          </select>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="property" value="Lote" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="property"
            v-model="form.propertyId"
            :disabled="properties === null"
          >
            <option v-if="properties" :value="null" selected>
              Seleccionar
            </option>
            <option
              v-for="property in properties"
              :key="property.id"
              :value="property.id"
            >
              {{ property.name }}
            </option>
          </select>
          <validation-errors
            :errors="errorFor('property')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="totalAmount" value="Precio total"></input-label>
          <input
            class=""
            id="totalAmount"
            type="number"
            v-model="form.totalAmount"
          />
          <validation-errors
            :errors="errorFor('totalAmount')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="initialAmountInvested"
            value="Enganche"
          ></input-label>
          <input
            class=""
            id="initialAmountInvested"
            type="number"
            v-model="form.initialAmountInvested"
          />
          <validation-errors
            :errors="errorFor('initialAmountInvested')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="initialSquareMeterPrice"
            value="Precio por m2 de compra"
          ></input-label>
          <input
            class=""
            id="initialSquareMeterPrice"
            type="number"
            v-model="form.initialSquareMeterPrice"
          />
          <validation-errors
            :errors="errorFor('initialSquareMeterPrice')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="saleSquareMeterPrice"
            value="Precio por m2 de venta"
          ></input-label>
          <input
            class=""
            id="saleSquareMeterPrice"
            type="number"
            v-model="form.saleSquareMeterPrice"
          />
          <validation-errors
            :errors="errorFor('saleSquareMeterPrice')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="purchaseDate" value="Fecha de compra"></input-label>
          <input
            class=""
            id="purchaseDate"
            type="datetime-local"
            v-model="form.purchaseDate"
          />
          <validation-errors
            :errors="errorFor('purchaseDate')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="saleDate" value="Fecha de venta"></input-label>
          <input class="" id="saleDate" type="datetime-local" v-model="form.saleDate" />
          <validation-errors :errors="errorFor('saleDate')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="investmentReturn"
            value="Retorno de inversión"
          ></input-label>
          <input
            class=""
            id="investmentReturn"
            type="number"
            v-model="form.investmentReturn"
          />
          <validation-errors
            :errors="errorFor('investmentReturn')"
          ></validation-errors>
        </div>
      </div>

      <primary-button
        class="mt-12 w-full sm:w-96"
        :loading="loading"
        @buttonClicked="createInvestment"
        >Crear Inversion</primary-button
      >
    </form>
  </div>
</template>

<script>
import api from "../../../../../api";
import validationErrors from "../../../../../mixins/validationErrors";
import { is422 } from "../../../../../utils/response";
export default {
  name: "Staged Investments Form",

  data() {
    return {
      clients: null,
      projects: null,
      blocks: null,
      properties: null,
      projectId: null,
      blockId: null,
      form: {
        clientId: null,
        propertyId: null,
        totalAmount: null,
        initialAmountInvested: null,
        initialSquareMeterPrice: null,
        saleSquareMeterPrice: null,
        purchaseDate: null,
        saleDate: null,
        investmentReturn: null,
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  created() {
    this.$store.commit("setSelectedTab", "adminInvestments");

    this.loadClients();
    this.loadProjects();
  },

  methods: {
    async loadClients() {
      try {
        this.clients = await api.clients.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async loadProjects() {
      try {
        this.projects = await api.projects.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async loadBlocks(projectId) {
      try {
        this.blocks = await api.projects.getBlocks(projectId);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async loadAvailableProperties(blockId) {
      try {
        this.properties = await api.blocks.getAvailableProperties(blockId);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    validateForm() {
      this.errors = {};

      if (!this.form.clientId) {
        this.errors["client"] = ["Campo requerido"];
      }

      if (!this.form.propertyId) {
        this.errors["property"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    async createInvestment() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.propertyInvestments.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "adminInvestmentsIndex" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },

  watch: {
    projectId(newProjectId) {
      if (newProjectId) {
        this.loadBlocks(newProjectId);
      }
    },

    blockId(newBlockId) {
      if (newBlockId) {
        this.loadAvailableProperties(newBlockId);
      }
    },
  },
};
</script>
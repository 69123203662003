<template>
  <primary-button class="mx-auto m-8" @buttonClicked="sendRequests"
    >Test</primary-button
  >
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      requestCount: 1000,
      requestData: {
        items: [
          {
            id: 1,
            name: "three",
            price: "10.00",
          },
          {
            id: 2,
            name: "four",
            price: "12.00",
          },
        ],
      },
    };
  },
  methods: {
    async sendRequests() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      };

      for (let i = 0; i < this.requestCount; i++) {
        try {
          // const response = await axios.post(
          //   "http://localhost:9090/items",
          //   this.requestData,
          //   config
          // );
          const response = await axios.get(
            "http://localhost:9090/test",
            config
          );
          console.log(`Request ${i + 1}:`, response.data);
        } catch (error) {
          console.error(`Error in request ${i + 1}:`, error);
        }
      }
    },
  },
};
</script>
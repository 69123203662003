export default {
  path: '/projects',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getAll() {
    try {
      const response = await this.apiClient.get(`${this.path}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getBlocks(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}/blocks`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getById(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async createImage(id, form) {
    try {
      const response = await this.apiClient.post(`${this.path}/${id}/image`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateName(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/name`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateAddress(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/address`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateMapUrl(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/map-url`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteImage(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}/image`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}
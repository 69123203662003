<template>
  <div>
    <transition name="modal">
      <delete
        v-if="showDeleteModal"
        :loading="loadingDelete"
        @close="close"
        @confirmDelete="deleteElement"
      ></delete>
    </transition>

    <transition name="modal">
      <investment-value-form
        v-if="showInvestmentValue"
        :propertyInvestmentId="investment.id"
        :investmentValue="investmentValueToUpdate"
        @close="showInvestmentValue = false"
        @confirm="loadInvestment"
      ></investment-value-form>
    </transition>

    <transition name="modal">
      <payment-form
        v-if="showPaymentForm"
        :propertyInvestmentId="investment.id"
        :payment="paymentToUpdate"
        @close="showPaymentForm = false"
        @confirm="loadInvestment"
      ></payment-form>
    </transition>

    <div class="flex items-center mb-6">
      <router-link
        :to="{ name: 'adminInvestmentsIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <div v-if="!loading">
      <div v-if="investment">
        <router-link
          :to="{
            name: 'adminClientsDetails',
            params: { id: investment.client.id },
          }"
          class="button-interactive relative text-white font-thin text-lg sm:text-3xl underline underline-offset-4"
          >{{ investment.client.name }} {{ investment.client.lastName }}
        </router-link>

        <div
          class="flex flex-col text-white font-thin text-lg sm:text-3xl mt-4"
        >
          <h1 class="">
            {{ investment.property.name }}
          </h1>

          <h1 class="">
            {{ investment.property.block.name }}
          </h1>

          <router-link
            :to="{
              name: 'adminProjectsDetails',
              params: { id: investment.property.block.project.id },
            }"
            class="button-interactive relative underline underline-offset-4"
            >{{ investment.property.block.project.name }}
          </router-link>
        </div>

        <d-b-flag
          class="mt-4"
          field="active"
          :value="investment.active"
          title="Activa"
          :id="investment.id"
          model="propertyInvestment"
          :resource="{ api: 'propertyInvestments', name: 'updateActive' }"
          @update="investment.active = $event"
        />

        <d-b-string
          class="mt-4"
          field="totalAmount"
          :value="`${investment.totalAmount}`"
          title="Precio total"
          :id="investment.id"
          model="propertyInvestment"
          :resource="{ api: 'propertyInvestments', name: 'updateTotalAmount' }"
          :active="true"
          type="number"
          @updateTotalAmount="investment.totalAmount = $event"
        />

        <d-b-string
          class="mt-4"
          field="initialAmountInvested"
          :value="`${investment.initialAmountInvested}`"
          title="Enganche"
          :id="investment.id"
          model="propertyInvestment"
          :resource="{
            api: 'propertyInvestments',
            name: 'updateInitialAmountInvested',
          }"
          :active="true"
          type="number"
          @updateInitialAmountInvested="
            investment.initialAmountInvested = $event
          "
        />

        <d-b-string
          class="mt-4"
          field="initialSquareMeterPrice"
          :value="`${investment.initialSquareMeterPrice}`"
          title="Precio por m2 de compra"
          :id="investment.id"
          model="propertyInvestment"
          :resource="{
            api: 'propertyInvestments',
            name: 'updateInitialSquareMeterPrice',
          }"
          :active="true"
          type="number"
          @updateInitialSquareMeterPrice="
            investment.initialSquareMeterPrice = $event
          "
        />

        <d-b-string
          class="mt-4"
          field="saleSquareMeterPrice"
          :value="`${investment.saleSquareMeterPrice}`"
          title="Precio por m2 de venta"
          :id="investment.id"
          model="propertyInvestment"
          :resource="{
            api: 'propertyInvestments',
            name: 'updateSaleSquareMeterPrice',
          }"
          :active="true"
          type="number"
          @updateSaleSquareMeterPrice="investment.saleSquareMeterPrice = $event"
        />

        <d-b-datetime
          class="mt-4"
          field="purchaseDate"
          :value="investment.purchaseDate"
          title="Fecha de compra"
          :id="investment.id"
          :resource="{ api: 'propertyInvestments', name: 'updatePurchaseDate' }"
          @updatePurchaseDate="investment.purchaseDate = $event"
        />

        <d-b-datetime
          class="mt-4"
          field="saleDate"
          :value="investment.saleDate"
          title="Fecha de venta"
          :id="investment.id"
          :resource="{ api: 'propertyInvestments', name: 'updateSaleDate' }"
          @updateSaleDate="investment.saleDate = $event"
        />

        <d-b-string
          class="mt-4"
          field="investmentReturn"
          :value="`${investment.investmentReturn}`"
          title="Retorno de inversión %"
          :id="investment.id"
          model="propertyInvestment"
          :resource="{
            api: 'propertyInvestments',
            name: 'updateInvestmentReturn',
          }"
          :active="true"
          type="number"
          @updateInvestmentReturn="investment.investmentReturn = $event"
        />

        <p class="mt-4">Área del lote: {{ investment.property.squareMeters }}m2</p>

        <div
          class="mt-8 flex flex-col bg-gradient-to-r from-content-800 to-content-700 w-full p-4 rounded-lg"
        >
          <h2>Valor</h2>

          <button
            class="secondary-button mt-4"
            @click="showInvestmentValue = true"
          >
            + Agregar Valor
          </button>

          <div class="flex flex-wrap">
            <div
              v-for="value in investment.investmentValues"
              :key="value.id"
              class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
            >
              <div class="flex space-x-2 items-center">
                <p class="font-semibold text-success-500">
                  Total: {{ integerToCurrency(value.value) }}
                </p>

                <button
                  class="button-interactive"
                  @click="
                    investmentValueToUpdate = value;
                    showInvestmentValue = true;
                  "
                >
                  <edit-icon class="fill-current w-4" />
                </button>

                <button
                  class="button-interactive delete-hover"
                  @click="
                    investmentValueToDelete = value;
                    openDeleteModal();
                  "
                >
                  <trashcan-icon class="fill-current w-4" />
                </button>
              </div>

              <p class="font-semibold text-primary-500">
                M2: {{ integerToCurrency(value.squareMeterPrice) }}
              </p>
              <p class="text-xs text-content-200 text-semibold font-light">
                {{ formatDate(value.date) }}
              </p>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row justify-center sm:space-x-8">
            <div class="my-6 flex flex-col">
              <h1
                class="text-success-500 font-light text-lg sm:text-3xl mx-auto"
              >
                {{ getCurrentValue(investment) }}
              </h1>
              <p class="mx-auto font-light text-sm sm:text-base">
                Valor actual
              </p>
            </div>

            <div class="my-6 flex flex-col">
              <h1
                class="text-success-500 font-light text-lg sm:text-3xl mx-auto"
              >
                {{ investment.investmentReturn }}%
              </h1>
              <p class="mx-auto font-light text-sm sm:text-base">
                {{
                  investment.active ? "Rendimiento actual" : "Rendimiento final"
                }}
              </p>
            </div>
          </div>

          <div class="mt-6">
            <LineChart
              :chartData="prepareChartData(investment)"
              :options="chartOptions"
            />
          </div>
        </div>

        <div
          class="mt-8 flex flex-col bg-gradient-to-r from-content-800 to-content-700 w-full p-4 rounded-lg mb-8"
        >
          <h2>Pagos</h2>

          <button class="secondary-button mt-4" @click="showPaymentForm = true">
            + Agregar Pago
          </button>

          <div class="flex flex-wrap">
            <div
              v-for="payment in investment.payments"
              :key="payment.id"
              class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
            >
              <div class="flex space-x-2 items-center">
                <p class="font-semibold text-primary-500">
                  {{ integerToCurrency(payment.amount) }}
                </p>

                <button
                  class="button-interactive"
                  @click="
                    paymentToUpdate = payment;
                    showPaymentForm = true;
                  "
                >
                  <edit-icon class="fill-current w-4" />
                </button>

                <button
                  class="button-interactive delete-hover"
                  @click="
                    paymentToDelete = payment;
                    openDeleteModal();
                  "
                >
                  <trashcan-icon class="fill-current w-4" />
                </button>
              </div>

              <p class="text-sm text-content-200 text-semibold font-light my-2">
                {{ payment.name }}
              </p>
              <p class="text-xs text-content-200 text-semibold font-light">
                {{ formatDate(payment.paymentDate) }}
              </p>
            </div>
          </div>

          <div class="mt-6 relative">
            <div
              class="absolute inset-0 text-center items-center justify-center flex flex-col text-sm sm:text-base"
            >
              <p class="text-success-500">
                {{ integerToCurrency(getTotalPaid(investment.payments)) }}
              </p>
              <p>de</p>
              <p>{{ integerToCurrency(investment.totalAmount) }}</p>
            </div>
            <DoughnutChart
              :chartData="prepareDoughnutChartData(investment)"
              :options="doughnutChartOptions"
            />
          </div>
        </div>

        <button
          class="mt-6 delete-button"
          @click="
            deleteInvestment = true;
            openDeleteModal();
          "
        >
          Eliminar Inversión
        </button>
      </div>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import InvestmentValueForm from "../../../../../components/modals/forms/InvestmentValue";
import PaymentForm from "../../../../../components/modals/forms/Payment";
import api from "../../../../../api";
import DBString from "../../../../../components/db-resources/String";
import DBFlag from "../../../../../components/db-resources/Flag";
import DBDatetime from "../../../../../components/db-resources/Datetime";
import validationErrors from "../../../../../mixins/validationErrors";
import deleteMixin from "../../../../../mixins/deleteMixin";
import { is422 } from "../../../../../utils/response";
import { LineChart, DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  name: "Staged Investments Detail",

  components: {
    InvestmentValueForm,
    PaymentForm,
    DBString,
    DBFlag,
    DBDatetime,
    LineChart,
    DoughnutChart,
  },

  data() {
    return {
      showInvestmentValue: false,
      showPaymentForm: false,
      investment: null,
      investmentValueToUpdate: null,
      investmentValueToDelete: null,
      paymentToUpdate: null,
      paymentToDelete: null,
      deleteInvestment: false,
      loading: false,
      loadingDelete: false,
    };
  },

  computed: {
    chartOptions() {
      return {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const tooltipItem = context.parsed;
                const dataset = context.dataset;
                const reason = dataset.reasons[context.dataIndex];
                return `Precio del m2: ${tooltipItem.y} - Razón: ${reason}`;
              },
            },
          },
        },
      };
    },

    doughnutChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        cutout: "70%",
      };
    },
  },

  mixins: [validationErrors, deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "adminInvestments");

    this.loadInvestment();
  },

  methods: {
    async loadInvestment() {
      try {
        this.investment = await api.propertyInvestments.getById(
          this.$route.params.id
        );
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    validateForm() {
      this.errors = {};

      if (!this.form.clientId) {
        this.errors["client"] = ["Campo requerido"];
      }

      if (!this.form.propertyId) {
        this.errors["property"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    async createInvestment() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      this.form["weddingId"] = this.$route.params.id;

      try {
        const response = await api.propertyInvestments.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "adminInvestmentsIndex" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);

      const options = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Mexico_City",
      };
      return date.toLocaleDateString("en-US", options);
    },

    integerToCurrency(amount) {
      if (!amount) {
        return "-";
      }

      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    getCurrentValue(investment) {
      return this.integerToCurrency(
        investment.investmentValues[investment.investmentValues.length - 1]
          ? investment.investmentValues[investment.investmentValues.length - 1]
              .value
          : null
      );
    },

    prepareChartData(investment) {
      const reasons = investment.investmentValues.map((entry) => entry.reason);
      return {
        labels: investment.investmentValues.map((entry) =>
          new Date(entry.date).toLocaleDateString()
        ),
        datasets: [
          {
            label: "Precio del m2",
            data: investment.investmentValues.map(
              (entry) => entry.squareMeterPrice
            ),
            borderColor: "#028cf3",
            fill: false,
            reasons: investment.investmentValues.map((entry) => entry.reason),
          },
        ],
      };
    },

    getTotalPaid(payments) {
      return payments.reduce((sum, payment) => sum + payment.amount, 0);
    },

    prepareDoughnutChartData(investment) {
      const totalPaid = this.getTotalPaid(investment.payments);
      const remaining = investment.totalAmount - totalPaid;

      return {
        labels: ["Pagado", "Restante"],
        datasets: [
          {
            data: [totalPaid, remaining],
            backgroundColor: ["#2feaa8", "#0b0d0e"],
            borderWidth: 0,
          },
        ],
      };
    },

    close() {
      this.valueToDelete = null;
      this.paymentToDelete = null;
      this.deleteInvestment = false;
      this.closeDeleteModal();
    },

    async deleteElement() {
      this.loadingDelete = true;

      if (this.investmentValueToDelete) {
        try {
          const response = await api.investmentValues.delete(
            this.investmentValueToDelete.id
          );
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.investmentValueToDelete = null;
          this.loadInvestment();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.paymentToDelete) {
        try {
          const response = await api.payments.delete(this.paymentToDelete.id);
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.paymentToDelete = null;
          this.loadInvestment();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.deleteInvestment) {
        try {
          const response = await api.propertyInvestments.delete(
            this.investment.id
          );
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.deleteProject = null;
          this.$router.push({ name: "adminInvestmentsIndex" });
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loadingDelete = false;
    },
  },
};
</script>
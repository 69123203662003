<template>
  <div class="flex flex-col">
    <input-label
      :for="model + '_' + field"
      :value="title"
      :details="details"
    ></input-label>

    <custom-toggle
      v-model="form[field]"
      @change="updateValue()"
    ></custom-toggle>
  </div>
</template>

<script>
import CustomToggle from "../ui/CustomToggle";
import api from "../../api";
export default {
  name: "DB Flag",

  components: {
    CustomToggle
  },

  data() {
    return {
      form: {},
      loading: false,
    };
  },

  props: {
    field: String,
    value: Boolean,
    title: String,
    details: String,
    id: Number,
    model: String,
    resource: Object,
    active: Boolean,
  },

  created() {
    this.form[this.field] = this.value;
  },

  methods: {
    async updateValue() {
      this.loading = true;

      try {
        const response = await api[this.resource.api][this.resource.name](
          this.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
        this.$emit("update", this.form[this.field]);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
<template>
  <div v-if="!loading">
    <div v-if="project">
      <div class="flex items-center space-x-2 sm:space-x-4">
        <router-link
          :to="{ name: previousProjectRoute }"
          class="button-interactive"
        >
          <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
        </router-link>
        <h1 class="text-white font-thin text-lg sm:text-3xl">{{ project.name }}</h1>
      </div>

      <div
        v-if="project.imageUrl"
        class="rounded-lg w-48 h-48 sm:w-72 sm:h-72 bg-cover relative mt-2"
        :style="{ backgroundImage: 'url(' + project.imageUrl + ')' }"
      ></div>

      <p class="mt-6 font-thin text-sm sm:text-base">
        Porcentaje de lotes vendidos:
        <span class="font-light">{{ saledPercentage }}%</span>
      </p>

      <p class="mt-1 font-thin text-sm sm:text-base">
        Número de lotes disponibles:
        <span class="font-light">{{ availableProperties }}</span>
      </p>

      <a
        class="mx-auto text-sm text-content-100 mt-4 block"
        :class="[{ 'button-interactive underline': project.mapUrl }]"
        :href="project.mapUrl"
        target="_blank"
        :disabled="!project.mapUrl"
      >
        {{ project.address }}
      </a>

      <!-- <h2 class="text-lg my-8">Archivos</h2>

      <div class="flex flex-wrap">
        <div
          v-for="file in project.files"
          :key="file.id"
          class="p-4 rounded-lg w-full sm:w-fit sm:mr-4 bg-content-600"
        >
          <div class="flex justify-between items-center">
            <p class="text-content-200 text-semibold font-light">
              {{ file.name }}
            </p>
            <button class="button-interactive">
              <downloadIcon class="w-4 fill-current stroke-current" />
            </button>
          </div>

          <p class="text-xs text-content-200 text-semibold font-light mx-t2">
            {{ formatDate(file.timestamp) }}
          </p>
        </div>
      </div> -->

      <p class="mt-6">Publicaciones</p>

      <div
        v-for="post in project.posts"
        :key="post.id"
        class="w-full bg-gradient-to-r from-content-800 to-content-700 p-4 rounded-lg mt-6"
      >
        <p>{{ post.title }}</p>
        <p class="mt-1 text-xs text-content-100">{{ post.content }}</p>
        <div
          v-if="post.type === 'image'"
          class="bg-contain bg-no-repeat w-auto h-96 bg-center rounded-b mt-2"
          v-bind:style="{
            backgroundImage: 'url(' + post.src + ')',
          }"
        />

        <iframe
          v-else-if="post.type === 'video'"
          class="w-full h-96 bg-black min-w-full rounded-b mt-2"
          :src="post.src"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        <p class="mt-2 text-xs text-content-200">
          {{ formatDate(post.createdAt) }}
        </p>
      </div>
    </div>
  </div>

  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import ArrowIcon from "../../../../icons/ArrowIcon";
import DownloadIcon from "../../../../icons/DownloadIcon";
import api from "../../../../api";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Project Detail",

  components: {
    ArrowIcon,
    DownloadIcon,
  },

  data() {
    return {
      loading: false,
      project: null,
      saledPercentage: null,
      availableProperties: null,
      // project: {
      //   id: 1,
      //   name: "La Joya",
      //   saledPercentage: 20,
      //   availableLots: 15,
      //   address: "44JC+WP, 67618 La Espía de Arriba, N.L.",
      //   map: "https://goo.gl/maps/JKWnJ3W8qtndGbDaA",
      //   imageUrl: require("@/assets/la_joya.jpeg"),
      //   files: [
      //     {
      //       id: 1,
      //       name: "Plano",
      //       timestamp: "2023-05-15T12:00:00",
      //     },
      //     {
      //       id: 2,
      //       name: "Escrituras",
      //       timestamp: "2023-05-15T12:00:00",
      //     },
      //   ],
      //   posts: [
      //     {
      //       id: 1,
      //       type: "image",
      //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, placeat id. Laborum voluptates perferendis, ab ducimus eius magni enim, quia delectus repellat quod temporibus voluptatibus. Ad fugiat et placeat fuga.",
      //       url: require("@/assets/la_joya.jpeg"),
      //       createdAt: "2023-08-14T12:00:00",
      //     },
      //     {
      //       id: 2,
      //       type: "video",
      //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, placeat id. Laborum voluptates perferendis, ab ducimus eius magni enim, quia delectus repellat quod temporibus voluptatibus. Ad fugiat et placeat fuga.",
      //       url: "https://www.youtube.com/embed/A16elkJVcaA",
      //       createdAt: "2023-08-14T12:00:00",
      //     },
      //     {
      //       id: 3,
      //       type: "text",
      //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, placeat id. Laborum voluptates perferendis, ab ducimus eius magni enim, quia delectus repellat quod temporibus voluptatibus. Ad fugiat et placeat fuga.",
      //       createdAt: "2023-08-14T12:00:00",
      //     },
      //   ],
      // },
    };
  },

  computed: {
    ...mapState({
      previousProjectRoute: "previousProjectRoute",
    }),
  },

  created() {
    this.$store.commit("setSelectedTab", "projects");

    this.loadProject();
  },

  methods: {
    async loadProject() {
      this.loading = true;

      try {
        const response = await api.resources.getProject(this.$route.params.id);
        this.project = response.project;
        this.saledPercentage = response.saledPercentage;
        this.availableProperties = response.availableProperties;
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
        this.$router.push({ name: "projectsIndex" });
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    integerToCurrency(amount) {
      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    postTime(date) {
      if (!date) {
        return "Sin hora asignada";
      }

      return moment(date).locale("es").format("LLL");
    },
  },
};
</script>
export default {
  path: '/resources',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getInvestments() {
    try {
      const response = await this.apiClient.get(`${this.path}/investments`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getProjects() {
    try {
      const response = await this.apiClient.get(`${this.path}/projects`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getProject(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/projects/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getOpportunities() {
    try {
      const response = await this.apiClient.get(`${this.path}/opportunities`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
}
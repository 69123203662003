import Delete from "../components/modals/Delete";
export default {
  components: {
    Delete
  },

  data() {
    return {
      showDeleteModal: false,
    }
  },

  methods: {
    openDeleteModal() {
      this.showDeleteModal = true;
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
    }
  }
}
<template>
  <div v-if="!loading">
    <p v-if="projects.length === 0">No tienes proyectos asignados</p>
    <div class="flex flex-wrap space-x-4">
      <router-link
        :to="{ name: 'projectsDetail', params: { id: project.id } }"
        v-for="project in projects"
        :key="project.id"
        class="rounded-lg w-48 h-48 sm:w-72 sm:h-72 bg-cover relative"
        :style="{ backgroundImage: 'url(' + project.imageUrl + ')' }"
      >
        <div class="absolute inset-0 bg-black bg-opacity-30 p-6 hover:bg-opacity-50 smooth">
          <p class="text-lg sm:text-xl text-white">{{ project.name }}</p>
        </div>
      </router-link>
    </div>
  </div>

  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import api from "../../../../api";
export default {
  name: "Project Index",

  data() {
    return {
      loading: false,
      projects: null,
    };
  },

  created() {
    this.$store.commit("setSelectedTab", "projects");
    this.$store.commit("setPreviousProjectRoute", "projectsIndex");

    this.loadProjects();
  },

  methods: {
    async loadProjects() {
      this.loading = true;

      try {
        this.projects = await api.resources.getProjects();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    }
  }
};
</script>
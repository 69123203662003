export default {
  path: '/property-investments',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async getAll() {
    try {
      const response = await this.apiClient.get(`${this.path}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getById(id) {
    try {
      const response = await this.apiClient.get(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async create(form) {
    try {
      const response = await this.apiClient.post(`${this.path}`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateActive(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/active`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateTotalAmount(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/total-amount`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateInitialAmountInvested(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/initial-amount-invested`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateInitialSquareMeterPrice(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/initial-square-meter-price`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateSaleSquareMeterPrice(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/sale-square-meter-price`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updatePurchaseDate(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/purchase-date`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateSaleDate(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/sale-date`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateInvestmentReturn(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/investment-return`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updatePropertyId(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/property-id`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateClientId(id, form) {
    try {
      const response = await this.apiClient.patch(`${this.path}/${id}/client-id`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async delete(id) {
    try {
      const response = await this.apiClient.delete(`${this.path}/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-50 bg-black"
  >
    <div class="modal">
      <form>
        <!-- <div class="flex flex-col w-full mt-2">
          <input-label for="value" value="Precio total"></input-label>
          <input class="w-full" id="value" type="number" v-model="form.value" />
          <validation-errors :errors="errorFor('value')"></validation-errors>
        </div> -->

        <div class="flex flex-col w-full mt-2">
          <input-label
            for="squareMeterPrice"
            value="Precio de m2"
          ></input-label>
          <input
            class="w-full"
            id="squareMeterPrice"
            type="number"
            v-model="form.squareMeterPrice"
          />
          <validation-errors
            :errors="errorFor('squareMeterPrice')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="reason" value="Razón"></input-label>
          <input class="w-full" id="reason" type="text" v-model="form.reason" />
          <validation-errors :errors="errorFor('reason')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="date" value="Fecha"></input-label>
          <input class="w-full" id="date" type="datetime-local" v-model="form.date" />
          <validation-errors :errors="errorFor('date')"></validation-errors>
        </div>

        <primary-button
          class="mt-12 w-full"
          :loading="loading"
          @buttonClicked="save"
          >{{ !isEditing ? "Crear" : "Editar" }} Valor</primary-button
        >
      </form>

      <button class="link mt-4 text-center" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
import moment from "moment-timezone";
export default {
  name: "Investment Value Form",

  data() {
    return {
      form: {
        squareMeterPrice: null,
        reason: null,
        propertyInvestmentId: null,
        date: null,
      },
      loading: false,
      isEditing: false,
    };
  },

  props: {
    propertyInvestmentId: Number,
    investmentValue: Object,
  },

  mixins: [validationErrors],

  created() {
    if (this.investmentValue) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.investmentValue));
      this.form.date = this.setDate(this.form.date);
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    setDate(date) {
      console.log(date)
      if (!date) {
        return null;
      }

      const dateWithTZ = moment.tz(date, "UTC");
      return dateWithTZ
        .clone()
        .tz("America/Mexico_City")
        .format("YYYY-MM-DDTHH:mm");
    },

    validateForm() {
      this.errors = {};

      // if (!this.form.value) {
      //   this.errors["value"] = ["Campo requerido"];
      // }

      if (!this.form.squareMeterPrice) {
        this.errors["squareMeterPrice"] = ["Campo requerido"];
      }

      if (!this.form.reason) {
        this.errors["reason"] = ["Campo requerido"];
      }

      if (!this.form.date) {
        this.errors["date"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      if (!this.isEditing) {
        this.createInvestmentValue();
      } else {
        this.updateInvestmentValue();
      }
    },

    async createInvestmentValue() {
      if (this.propertyInvestmentId) {
        this.form.propertyInvestmentId = this.propertyInvestmentId;
      } else {
        return;
      }

      this.loading = true;

      try {
        const response = await api.investmentValues.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async updateInvestmentValue() {
      this.loading = true;

      try {
        const response = await api.investmentValues.update(
          this.investmentValue.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
<template>
  <div v-if="!loading">
    <div v-if="investments" class="h-full flex flex-col">
      <div class="flex space-x-8 mx-auto mb-6">
        <button
          class="relative text-white text-sm sm:text-base"
          v-for="type in investmentType"
          :key="type.id"
          @click="selectedType = type.id"
        >
          {{ type.display }}
          <transition name="expand">
            <div
              v-show="selectedType === type.id"
              class="absolute -bottom-2 w-full h-[2px] bg-primary-500 rounded"
            ></div>
          </transition>
        </button>
      </div>

      <div v-if="selectedType === 1" class="overflow-auto">
        <p v-if="investments.propertyInvestments.length === 0">
          No tienes inversiones inmobiliarias asignadas
        </p>
        <div
          v-for="investment in investments.propertyInvestments"
          :key="investment.id"
          class="flex flex-col bg-gradient-to-r from-content-800 to-content-700 w-full p-4 rounded-lg mb-8"
        >
          <div
            class="flex flex-col sm:flex-row text-white font-thin sm:text-2xl sm:space-x-4"
          >
            <p>{{ investment.property.name }}</p>
            <p>{{ investment.property.block.name }}</p>

            <router-link
              :to="{
                name: 'projectsDetail',
                params: { id: investment.property.block.project.id },
              }"
              class="button-interactive relative w-fit underline underline-offset-4"
              >{{ investment.property.block.project.name }}
            </router-link>
          </div>

          <p
            class="mt-2 text-sm font-light"
            :class="[
              { 'text-success-500': investment.active },
              { 'text-primary-500': !investment.active },
            ]"
          >
            {{ investment.active ? "Activa" : "Finalizada" }}
          </p>

          <p class="mt-6 font-thin text-sm sm:text-base">
            Fecha de inversión:
            <span class="font-light">{{
              formatDate(investment.purchaseDate)
            }}</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Área:
            <span class="font-light"
              >{{ investment.property.squareMeters }}m2</span
            >
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Enganche:
            <span class="font-light">{{
              integerToCurrency(investment.initialAmountInvested)
            }}</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Precio por m2 de compra:
            <span class="font-light">{{
              integerToCurrency(investment.initialSquareMeterPrice)
            }}</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Precio por m2 de venta:
            <span class="font-light">{{
              integerToCurrency(investment.saleSquareMeterPrice)
            }}</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Fecha de venta:
            <span class="font-light">{{
              formatDate(investment.saleDate)
            }}</span>
          </p>

          <div class="flex flex-col sm:flex-row justify-center sm:space-x-8">
            <div class="my-6 flex flex-col">
              <h1
                class="text-success-500 font-light text-lg sm:text-3xl mx-auto"
              >
                {{ getCurrentValue(investment) }}
              </h1>
              <p class="mx-auto font-light text-sm sm:text-base">
                Valor actual
              </p>
            </div>

            <div class="my-6 flex flex-col">
              <h1
                class="text-success-500 font-light text-lg sm:text-3xl mx-auto"
              >
                {{ investment.investmentReturn }}%
              </h1>
              <p class="mx-auto font-light text-sm sm:text-base">
                {{
                  investment.active ? "Rendimiento actual" : "Rendimiento final"
                }}
              </p>
            </div>
          </div>

          <div class="mt-6">
            <LineChart
              :chartData="prepareChartData(investment)"
              :options="chartOptions"
            />
          </div>

          <div class="mt-8">
            <h2>Pagos</h2>

            <div class="flex flex-wrap">
              <div
                v-for="payment in investment.payments"
                :key="payment.id"
                class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
              >
                <p class="font-semibold text-primary-500">
                  {{ integerToCurrency(payment.amount) }}
                </p>
                <p
                  class="text-sm text-content-200 text-semibold font-light my-2"
                >
                  {{ payment.name }}
                </p>
                <p class="text-xs text-content-200 text-semibold font-light">
                  {{ formatDate(payment.paymentDate) }}
                </p>
              </div>
            </div>

            <div class="mt-6 relative">
              <div
                class="absolute inset-0 text-center items-center justify-center flex flex-col text-sm sm:text-base"
              >
                <p class="text-success-500">
                  {{ integerToCurrency(getTotalPaid(investment.payments)) }}
                </p>
                <p>de</p>
                <p>{{ integerToCurrency(investment.totalAmount) }}</p>
              </div>
              <DoughnutChart
                :chartData="prepareDoughnutChartData(investment)"
                :options="doughnutChartOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedType === 2" class="overflow-auto">
        <p v-if="investments.stagedInvestments.length === 0">
          No tienes inversiones por rendimiento asignadas
        </p>
        <div
          v-for="investment in investments.stagedInvestments"
          :key="investment.id"
          class="flex flex-col bg-gradient-to-r from-content-800 to-content-700 w-full p-4 rounded-lg mb-8"
        >
          <div class="flex text-white font-thin sm:text-3xl">
            <h1 class="">{{ investment.name }}</h1>
          </div>

          <p
            class="mt-1 text-sm font-light"
            :class="[
              { 'text-success-500': investment.active },
              { 'text-primary-500': !investment.active },
            ]"
          >
            {{ investment.active ? "Activa" : "Finalizada" }}
          </p>

          <p class="mt-6 font-thin text-sm sm:text-base">
            Fecha de inversión:
            <span class="font-light">{{
              formatDate(investment.purchaseDate)
            }}</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Período de inversión:
            <span class="font-light">{{ investment.timeLapse }} meses</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Monto de inversión:
            <span class="font-light">{{
              integerToCurrency(investment.initialAmountInvested)
            }}</span>
          </p>
          <p class="mt-1 font-thin text-sm sm:text-base">
            Inversión total liberada:
            <span class="font-light">{{
              integerToCurrency(investment.totalReleased)
            }}</span>
          </p>

          <div class="flex flex-col sm:flex-row justify-center sm:space-x-8">
            <div class="my-6 flex flex-col">
              <h1
                class="text-success-500 font-light text-lg sm:text-3xl mx-auto"
              >
                {{ getEstimatedValue(investment) }}
              </h1>
              <p class="mx-auto font-light text-sm sm:text-base">
                Monto de retorno estimado
              </p>
            </div>

            <div class="my-6 flex flex-col">
              <h1
                class="text-success-500 font-light text-lg sm:text-3xl mx-auto"
              >
                {{ investment.investmentReturn }}%
              </h1>
              <p class="mx-auto font-light text-sm sm:text-base">Rendimiento</p>
            </div>
          </div>

          <div class="mt-6 relative">
            <div
              class="absolute inset-0 text-center items-center justify-center flex flex-col text-sm sm:text-base"
            >
              <p class="text-success-500">
                {{ getMonthsPassed(investment) }}
              </p>
              <p>de</p>
              <p>{{ investment.timeLapse }} meses</p>
            </div>
            <DoughnutChart
              :chartData="prepareStagedDoughnutChartData(investment)"
              :options="doughnutChartOptions"
            />
          </div>

          <div class="mt-8">
            <h2>Pagos</h2>

            <div class="flex flex-wrap">
              <div
                v-for="payment in investment.payments"
                :key="payment.id"
                class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
              >
                <p class="font-semibold text-primary-500">
                  {{ integerToCurrency(payment.amount) }}
                </p>
                <p
                  class="text-sm text-content-200 text-semibold font-light my-2"
                >
                  {{ payment.name }}
                </p>
                <p class="text-xs text-content-200 text-semibold font-light">
                  {{ formatDate(payment.paymentDate) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import api from "../../../api";
import { LineChart, DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
export default {
  name: "Investments",

  components: {
    LineChart,
    DoughnutChart,
  },

  data() {
    return {
      loading: false,
      selectedType: 1,
      investments: null,
      investmentType: [
        {
          id: 1,
          slug: "property",
          display: "Inmobiliarias",
        },
        {
          id: 2,
          slug: "staged",
          display: "Por rendimiento",
        },
      ],
    };
  },

  computed: {
    chartOptions() {
      return {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const tooltipItem = context.parsed;
                const dataset = context.dataset;
                const reason = dataset.reasons[context.dataIndex];
                return `Precio del m2: ${tooltipItem.y} - Razón: ${reason}`;
              },
            },
          },
        },
      };
    },

    doughnutChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        cutout: "70%",
      };
    },
  },

  created() {
    this.$store.commit("setSelectedTab", "investments");
    this.$store.commit("setPreviousProjectRoute", "investments");

    this.loadInvestments();
  },

  methods: {
    async loadInvestments() {
      this.loading = true;

      try {
        this.investments = await api.resources.getInvestments();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    integerToCurrency(amount) {
      if (!amount) {
        return "-";
      }

      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    getCurrentValue(investment) {
      return this.integerToCurrency(
        investment.investmentValues[investment.investmentValues.length - 1]
          ? investment.investmentValues[investment.investmentValues.length - 1]
              .value
          : null
      );
    },

    prepareChartData(investment) {
      const reasons = investment.investmentValues.map((entry) => entry.reason);
      return {
        labels: investment.investmentValues.map((entry) =>
          new Date(entry.date).toLocaleDateString()
        ),
        datasets: [
          {
            label: "Precio del m2",
            data: investment.investmentValues.map(
              (entry) => entry.squareMeterPrice
            ),
            borderColor: "#028cf3",
            fill: false,
            reasons: investment.investmentValues.map((entry) => entry.reason),
          },
        ],
      };
    },

    getTotalPaid(payments) {
      return payments.reduce((sum, payment) => sum + payment.amount, 0);
    },

    getEstimatedValue(investment) {
      return this.integerToCurrency(
        investment.initialAmountInvested *
          (investment.investmentReturn / 100 + 1)
      );
    },

    prepareDoughnutChartData(investment) {
      const totalPaid = this.getTotalPaid(investment.payments);
      const remaining = investment.totalAmount - totalPaid;

      return {
        labels: ["Pagado", "Restante"],
        datasets: [
          {
            data: [totalPaid, remaining],
            backgroundColor: ["#2feaa8", "#0b0d0e"],
            borderWidth: 0,
          },
        ],
      };
    },

    getMonthsPassed(investment) {
      const purchaseDate = new Date(investment.purchaseDate);
      const currentDate = new Date();

      return (
        (currentDate.getFullYear() - purchaseDate.getFullYear()) * 12 +
        currentDate.getMonth() -
        purchaseDate.getMonth()
      );
    },

    prepareStagedDoughnutChartData(investment) {
      const monthsPassed = this.getMonthsPassed(investment);

      const remainingMonths = Math.max(0, investment.timeLapse - monthsPassed);

      const adjustedMonthsPassed = Math.min(monthsPassed, investment.timeLapse);

      return {
        labels: ["Transcurrido", "Restante"],
        datasets: [
          {
            data: [adjustedMonthsPassed, remainingMonths],
            backgroundColor: ["#2feaa8", "#0b0d0e"],
            borderWidth: 0,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.2s ease-in-out;
}
.expand-enter-from, .expand-leave-to /* .expand-leave-active in <2.1.8 */ {
  width: 0;
  left: 50%;
}
.expand-enter-to, .expand-leave-from /* .expand-leave-active in <2.1.8 */ {
  width: 100%;
  left: 0;
}
</style>
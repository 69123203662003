<template>
  <div v-if="!loading">
    <div v-if="opportunities">
      <p v-if="opportunities.length === 0">
        No hay oportunidades de inversión actualmente
      </p>

      <div class="flex flex-col space-y-4">
        <div
          v-for="opportunity in opportunities"
          :key="opportunity.id"
          class="w-full bg-gradient-to-r from-content-800 to-content-700 p-4 rounded-lg"
        >
          <p>{{ opportunity.title }}</p>

          <p class="mt-1 text-xs text-content-100">{{ opportunity.content }}</p>

          <div
            v-if="opportunity.type === 'image'"
            class="bg-contain bg-no-repeat w-auto h-96 bg-center rounded-b mt-2"
            v-bind:style="{
              backgroundImage: 'url(' + opportunity.src + ')',
            }"
          />

          <iframe
            v-else-if="opportunity.type === 'video'"
            class="w-full h-96 bg-black min-w-full rounded-b mt-2"
            :src="opportunity.src"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <h2 v-if="opportunity.files && opportunity.files.length > 0" class="mt-6">Archivos</h2>

          <div class="flex flex-wrap">
            <div
              v-for="file in opportunity.files"
              :key="file.id"
              class="p-4 rounded-lg mt-2 w-full sm:w-fit sm:mr-4 bg-content-600"
            >
              <p class="text-sm text-content-200 text-semibold font-light my-2">
                {{ file.description }}
              </p>

              <button class="secondary-button" @click="downloadFile(file)">
                Descargar
              </button>
            </div>
          </div>

          <p class="mt-4 text-xs text-content-200">
            {{ formatDate(opportunity.createdAt) }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="flex w-full h-full items-center">
    <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
  </div>
</template>

<script>
import api from "../../../api";
export default {
  name: "Opportunities",

  data() {
    return {
      loading: false,
      opportunities: null,
    };
  },

  created() {
    this.$store.commit("setSelectedTab", "opportunities");

    this.loadOpportunities();
  },

  methods: {
    async loadOpportunities() {
      this.loading = true;

      try {
        this.opportunities = await api.resources.getOpportunities();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    downloadFile(file) {
      fetch(file.url, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          let filename = file.fileName;
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (filenameMatch.length > 1) filename = filenameMatch[1];
          }

          return response.blob().then((blob) => ({
            blob,
            filename,
          }));
        })
        .then(({ blob, filename }) => {
          const fileUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((error) => console.error("Download error:", error));
    },
  },
};
</script>
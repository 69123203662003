<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-50 bg-black"
  >
    <div class="modal">
      <form>
        <div class="flex flex-col w-full mt-2">
          <input-label for="type" value="Forma de pago"></input-label>
          <input class="w-full" id="type" type="text" v-model="form.type" />
          <validation-errors :errors="errorFor('type')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="amount" value="Monto total"></input-label>
          <input
            class="w-full"
            id="amount"
            type="number"
            v-model="form.amount"
          />
          <validation-errors :errors="errorFor('amount')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="name" value="Nombre del pago"></input-label>
          <input class="w-full" id="name" type="text" v-model="form.name" />
          <validation-errors :errors="errorFor('name')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="paymentDate" value="Fecha de pago"></input-label>
          <input
            class="w-full"
            id="paymentDate"
            type="datetime-local"
            v-model="form.paymentDate"
          />
          <validation-errors
            :errors="errorFor('paymentDate')"
          ></validation-errors>
        </div>

        <primary-button
          class="mt-12 w-full"
          :loading="loading"
          @buttonClicked="save"
          >{{ !isEditing ? "Crear" : "Editar" }} Pago</primary-button
        >
      </form>

      <button class="link mt-4 text-center" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
import moment from "moment-timezone";
export default {
  name: "Payment Form",

  data() {
    return {
      form: {
        type: null,
        amount: null,
        name: null,
        paymentDate: null,
        propertyInvestmentId: null,
        stagedInvestmentId: null,
      },
      loading: false,
      isEditing: false,
    };
  },

  props: {
    propertyInvestmentId: Number,
    stagedInvestmentId: Number,
    payment: Object,
  },

  mixins: [validationErrors],

  created() {
    if (this.payment) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.payment));
      this.setInitialDate(); 
    }
  },

  methods: {
    setInitialDate() {
      const dateWithTZ = moment.tz(this.payment.paymentDate, "UTC");
      const formattedDate = dateWithTZ
        .clone()
        .tz("America/Mexico_City")
        .format("YYYY-MM-DDTHH:mm");

      this.form.paymentDate = formattedDate;
    },

    close() {
      this.$emit("close");
    },

    validateForm() {
      this.errors = {};

      if (!this.form.type) {
        this.errors["type"] = ["Campo requerido"];
      }

      if (!this.form.amount) {
        this.errors["amount"] = ["Campo requerido"];
      }

      if (!this.form.name) {
        this.errors["name"] = ["Campo requerido"];
      }

      if (!this.form.paymentDate) {
        this.errors["paymentDate"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      if (!this.isEditing) {
        this.createPayment();
      } else {
        this.updatePayment();
      }
    },

    async createPayment() {
      if (this.propertyInvestmentId) {
        this.form.propertyInvestmentId = this.propertyInvestmentId;
      } else {
        this.form.stagedInvestmentId = this.stagedInvestmentId;
      }

      this.loading = true;

      try {
        const response = await api.payments.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async updatePayment() {
      this.loading = true;

      try {
        const response = await api.payments.update(this.payment.id, this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>

<style>
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
</style>
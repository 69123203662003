<template>
  <div>
    <transition name="modal">
      <delete
        v-if="showDeleteModal"
        :loading="loadingDelete"
        @close="close"
        @confirmDelete="deleteElement"
      ></delete>
    </transition>

    <transition name="modal">
      <file-form
        v-if="showFileForm"
        :opportunityId="form.id"
        @close="close"
        @confirm="fileUploaded()"
      ></file-form>
    </transition>

    <div class="flex items-center">
      <router-link
        :to="{ name: 'adminOpportunitiesIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <form class="">
      <div class="flex flex-wrap">
        <div class="flex flex-col w-full mt-2 sm:w-96 sm:mr-4">
          <input-label for="type" value="Tipo" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="type"
            v-model="form.type"
            :disabled="isEditing"
          >
            <option value="text">Texto</option>
            <option value="image">Imagen</option>
            <option value="video">Video</option>
          </select>
          <validation-errors :errors="errorFor('')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2 sm:w-96 sm:mr-4">
          <input-label for="title" value="Título"></input-label>
          <input class="w-full" id="title" type="text" v-model="form.title" />
          <validation-errors :errors="errorFor('title')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2 sm:w-96 sm:mr-4">
          <input-label for="title" value="Contenido"></input-label>
          <textarea
            class="h-24 w-full"
            id="title"
            v-model="form.content"
          ></textarea>
          <!-- <input class="w-full" id="title" type="text" v-model="form.title" /> -->
          <validation-errors :errors="errorFor('content')"></validation-errors>
        </div>

        <div
          v-if="form.type === 'video'"
          class="flex flex-col w-full mt-2 sm:w-96 sm:mr-4"
        >
          <input-label for="src" value="URL de video"></input-label>
          <input class="w-full" id="src" type="text" v-model="form.src" />
          <validation-errors :errors="errorFor('src')"></validation-errors>
        </div>
      </div>

      <div v-if="form.type === 'image'" class="flex items-center mt-4">
        <div v-if="!imagePreview" class="w-full">
          <input
            class="hidden"
            type="file"
            name="image"
            id="image"
            ref="image"
            accept="image/*"
            @change="getImage"
          />

          <label for="image" class="cursor-pointer secondary-button"
            >+ Agregar Imagen</label
          >

          <validation-errors
            class="mt-2 block"
            :errors="errorFor('image')"
          ></validation-errors>
        </div>

        <div v-else class="flex items-center">
          <img
            ref="imagePreview"
            class="rounded-lg object-cover object-center mr-4"
            :class="[{ 'animate-pulse': loading }]"
            :style="{
              height: '100px',
            }"
            :src="imagePreview"
          />
          <button
            v-if="!loading"
            class="button-interactive delete-hover"
            @click.prevent="
              imagePreview = null;
              newImage = null;
            "
          >
            <close-icon class="w-4 fill-current" />
          </button>
        </div>
      </div>

      <p class="mt-4">Clientes</p>
      <p class="text-content-200 text-sm font-light">
        Selecciona a los clientes que podrán visualizar la oportunidad.
      </p>

      <validation-errors :errors="errorFor('clients')"></validation-errors>

      <button class="mt-4 secondary-button" @click.prevent="selectAllClients()">
        Agregar todos
      </button>

      <div class="flex flex-wrap">
        <button
          v-for="client in clients"
          :key="client.id"
          class="mt-4 p-2 w-fit border button-interactive rounded mr-2"
          :class="[
            { 'border-white text-white': !form.clientIds.includes(client.id) },
            {
              'border-primary-500 text-primary-500': form.clientIds.includes(
                client.id
              ),
            },
          ]"
          @click.prevent="selectClient(client.id)"
        >
          {{ client.name }} {{ client.lastName }}
        </button>
      </div>

      <primary-button
        class="mt-12 w-full sm:w-96"
        :loading="loading"
        @buttonClicked="save"
        >{{ isEditing ? "Actualizar" : "Crear" }} Oportunidad</primary-button
      >
    </form>

    <div
      v-if="isEditing"
      class="mt-8 flex flex-col bg-gradient-to-r from-content-800 to-content-700 w-full p-4 rounded-lg mb-8"
    >
      <h2>Archivos</h2>

      <button class="secondary-button mt-4" @click="showFileForm = true">
        + Agregar Archivo
      </button>

      <div class="flex flex-wrap">
        <div
          v-for="file in files"
          :key="file.id"
          class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
        >
          <div class="flex space-x-2 items-center">
            <p class="text-sm text-content-200 text-semibold font-light my-2">
              {{ file.description }}
            </p>

            <button
              class="button-interactive delete-hover"
              @click="
                fileToDelete = file;
                openDeleteModal();
              "
            >
              <trashcan-icon class="fill-current w-4" />
            </button>
          </div>

          <button class="secondary-button" @click="downloadFile(file)">
            Descargar
          </button>

          <p class="text-xs text-content-200 text-semibold font-light mt-2">
            {{ formatDate(file.createdAt) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileForm from "../../../../components/modals/forms/File";
import api from "../../../../api";
import validationErrors from "../../../../mixins/validationErrors";
import deleteMixin from "../../../../mixins/deleteMixin";
import { is422 } from "../../../../utils/response";
export default {
  name: "Opportunity Form",

  components: {
    FileForm
  },

  data() {
    return {
      clients: null,
      form: {
        title: "",
        content: "",
        type: "text",
        src: "",
        clientIds: [],
      },
      loading: false,
      loadingDelete: false,
      isEditing: false,
      imagePreview: null,
      newImage: null,
      showFileForm: false,
      files: [],
      fileToDelete: null,
    };
  },

  mixins: [validationErrors, deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "adminOpportunitiesForm");
    this.loadClients();
    if (this.$route.params.id) {
      this.isEditing = true;
      this.loadOpportunity(this.$route.params.id);
    }
  },

  methods: {
    async loadClients() {
      try {
        this.clients = await api.clients.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    async loadOpportunity(id) {
      try {
        const opportunity = await api.opportunities.getById(id);
        this.files = opportunity.files;
        opportunity.clientIds = opportunity.Clients.map((client) => client.id);
        delete opportunity.Clients;

        this.imagePreview = opportunity.src;

        this.form = JSON.parse(JSON.stringify(opportunity));
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    selectAllClients() {
      this.form.clientIds = [];

      this.clients.forEach((client) => {
        this.form.clientIds.push(client.id);
      });
    },

    selectClient(id) {
      const index = this.form.clientIds.indexOf(id);

      if (index === -1) {
        this.form.clientIds.push(id);
      } else {
        this.form.clientIds.splice(index, 1);
      }
    },

    fileUploaded() {
      this.close();
      this.loadOpportunity(this.form.id);
    },

    close() {
      this.closeDeleteModal();
      this.showFileForm = false;
    },

    getImage(e) {
      let image = e.target.files[0];
      if (image) {
        let url = URL.createObjectURL(image);
        let img = new Image();

        img.onload = () => {
          URL.revokeObjectURL(url);
          let reader = new FileReader();
          reader.onload = (e) => {
            this.newImage = image;
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(image);
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          alert("Unable to load image");
        };

        img.src = url;
      }
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);

      const options = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "America/Mexico_City",
      };
      return date.toLocaleDateString("en-US", options);
    },

    downloadFile(file) {
      fetch(file.url, {
        method: 'GET',
        headers: {},
      })
      .then(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = file.fileName;
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
          if (filenameMatch.length > 1) filename = filenameMatch[1];
        }

        return response.blob().then(blob => ({
          blob,
          filename
        }));
      })
      .then(({ blob, filename }) => {
        const fileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(error => console.error('Download error:', error));
    },

    validateForm() {
      this.errors = {};

      if (!this.form.title) {
        this.errors["title"] = ["Campo requerido"];
      }

      if (this.form.type === "image" && !this.newImage && !this.isEditing) {
        this.errors["image"] = ["Es necesario agregar una imagen"];
      }

      if (this.form.type === "video" && !this.form.src) {
        this.errors["src"] = ["Es necesario agregar un video"];
      }

      if (this.form.clientIds.length === 0) {
        this.errors["clients"] = ["Es necesario agregar al menos un cliente"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      if (!this.isEditing) {
        this.createOpportunity();
      } else {
        this.updateOpportunity();
      }
    },

    async createOpportunity() {
      this.loading = true;

      if (this.form.type !== "image") {
        try {
          const response = await api.opportunities.create(this.form);
          this.$store.dispatch("successSnack", response.message);
          this.$router.push({ name: "adminOpportunitiesIndex" });
        } catch (error) {
          if (is422(error)) {
            this.errors = error?.response?.data?.errors;
          }
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else {
        const formData = new FormData();

        formData.append("type", this.form.type);
        formData.append("title", this.form.title);
        formData.append("content", this.form.content);
        formData.append("clientIds", JSON.stringify(this.form.clientIds));
        formData.append("image", this.newImage);

        try {
          const response = await api.opportunities.createWithImage(formData);
          this.$store.dispatch("successSnack", response.message);
          this.$router.push({ name: "adminOpportunitiesIndex" });
        } catch (error) {
          if (is422(error)) {
            this.errors = error?.response?.data?.errors;
          }
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loading = false;
    },

    async updateOpportunity() {
      this.loading = true;

      try {
        const response = await api.opportunities.update(
          this.form.id,
          this.form
        );
        this.$store.dispatch("successSnack", response.message);
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async deleteElement() {
      this.closeDeleteModal();

      this.loadingDelete = true;

      try {
        const response = await api.opportunities.deleteFile(
          this.fileToDelete.id
        );
        this.$store.dispatch("successSnack", response.message);
        this.fileToDelete = null;
        this.fileUploaded();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingDelete = false;
    },
  },
};
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 477.297 477.297"
  >
    <path
      d="M42.85 358.075V27.158h302.767v99.722c5.846-1.079 11.842-1.812 17.99-1.812 3.149 0 6.126.647 9.232.928V0H15.649v385.233h224.638v-27.158H42.85z"
    />
    <path
      d="M81.527 206.842h184.495c1.812-10.16 5.393-19.608 10.095-28.452H81.527v28.452zM81.527 89.432h225.372v28.452H81.527zM81.527 295.822h191.268c5.112-3.106 10.57-5.63 16.415-7.183-5.544-6.45-10.095-13.697-13.978-21.269H81.527v28.452zM363.629 298.669c41.071 0 74.16-33.197 74.16-74.139 0-40.984-33.09-74.16-74.16-74.16-40.898 0-74.009 33.176-74.009 74.16 0 40.942 33.111 74.139 74.009 74.139zM423.143 310.706H304.288c-21.226 0-38.612 19.457-38.612 43.422v119.33c0 1.316.604 2.481.69 3.84h194.59c.086-1.337.69-2.524.69-3.84v-119.33c.087-23.901-17.256-43.422-38.503-43.422z"
    />
  </svg>
</template>
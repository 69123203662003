<template>
  <div
    class="text-xs text-red-500"
    v-for="(error, index) in errors"
    :key="index"
  >
    {{ error }}
  </div>
</template>

<script>
export default {
  props: ["errors"],
};
</script>
<template>
  <div>
    <transition name="modal">
      <delete
        v-if="showDeleteModal"
        :loading="loadingDelete"
        @close="close"
        @confirmDelete="deleteElement"
      ></delete>
    </transition>

    <transition name="modal">
      <block-form
        v-if="showBlock"
        :projectId="project.id"
        :block="blockToUpdate"
        @close="showBlock = false"
        @confirm="loadProject"
      ></block-form>
    </transition>

    <transition name="modal">
      <property-form
        v-if="showProperty"
        :blockId="selectedBlock"
        :property="propertyToUpdate"
        @close="showProperty = false"
        @confirm="loadProject"
      ></property-form>
    </transition>

    <transition name="modal">
      <post-form
        v-if="showPost"
        :projectId="project.id"
        :post="postToUpdate"
        @close="showPost = false; postToUpdate = null"
        @confirm="loadProject"
      ></post-form>
    </transition>

    <div v-if="!loading">
      <div v-if="project" class="flex flex-col">
        <div class="flex items-center space-x-2">
          <router-link
            :to="{ name: 'adminProjectsIndex' }"
            class="fill-current button-interactive"
          >
            <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
          </router-link>
        </div>

        <p class="mt-4">{{ project.name }}</p>

        <d-b-string
          class="mt-4"
          field="name"
          :value="project.name"
          title="Nombre"
          :id="project.id"
          model="project"
          :resource="{ api: 'projects', name: 'updateName' }"
          :active="true"
          @updateName="project.name = $event"
        />

        <d-b-string
          class="mt-4"
          field="address"
          :value="project.address"
          title="Dirección"
          :id="project.id"
          model="project"
          :resource="{ api: 'projects', name: 'updateAddress' }"
          :active="true"
          @updateLastName="project.address = $event"
        />

        <d-b-string
          class="mt-4"
          field="mapUrl"
          :value="project.mapUrl"
          title="URL de mapa"
          :id="project.id"
          model="project"
          :resource="{ api: 'projects', name: 'updateMapUrl' }"
          :active="true"
          @updateEmail="project.user.email = $event"
        />

        <div
          v-if="project.imageUrl"
          class="flex-shrink-0 rounded-lg inset-0 object-cover overflow-hidden mr-4 mb-2 relative w-32 mt-6"
          :class="[{ 'animate-pulse': loadingImageDelete }]"
        >
          <img class="w-full h-full" :src="project.imageUrl" loading="lazy" />
          <button
            v-if="!loadingImageDelete"
            class="w-8 h-8 fill-current button-interactive absolute bg-black right-2 top-2 p-2 rounded-full delete-hover"
            @click.prevent="
              showDeleteModal = true;
              deleteImage = true;
            "
          >
            <trashcan-icon />
          </button>
        </div>

        <div v-else class="flex items-center mt-6">
          <div v-if="!imagePreview" class="w-full">
            <input
              class="hidden"
              type="file"
              name="image"
              id="image"
              ref="image"
              accept="image/*"
              @change="getImage"
            />

            <label for="image" class="cursor-pointer secondary-button"
              >+ Agregar Imagen</label
            >
          </div>

          <div v-else class="flex items-center space-x-4">
            <img
              ref="imagePreview"
              class="rounded-lg object-cover w-32"
              :class="[{ 'animate-pulse': loadingImageCreate }]"
              :src="imagePreview"
            />
            <button
              v-if="!loading"
              class="button-interactive delete-hover"
              @click.prevent="
                imagePreview = null;
                newImage = null;
              "
            >
              <close-icon class="w-4 fill-current" />
            </button>

            <button class="button-interactive" @click.prevent="storeImage()">
              <check-icon class="w-8 fill-current" />
            </button>
          </div>
        </div>

        <p class="mt-6">Manzanas</p>

        <button class="mt-6 secondary-button" @click="showBlock = true">
          + Crear Manzana
        </button>

        <div
          v-for="block in project.blocks"
          :key="block.id"
          class="w-full bg-gradient-to-r from-content-800 to-content-700 p-4 rounded-lg mt-6"
        >
          <div class="flex space-x-2 items-center">
            <p>{{ block.name }}</p>
            <button
              class="button-interactive"
              @click="
                blockToUpdate = block;
                showBlock = true;
              "
            >
              <edit-icon class="fill-current w-4" />
            </button>

            <button
              class="button-interactive delete-hover"
              @click="
                blockToDelete = block;
                openDeleteModal();
              "
            >
              <trashcan-icon class="fill-current w-4" />
            </button>
          </div>

          <button
            class="mt-4 secondary-button"
            @click="
              selectedBlock = block.id;
              showProperty = true;
            "
          >
            + Crear Lote
          </button>

          <div class="flex flex-wrap">
            <div
              v-for="property in block.properties"
              :key="property.id"
              class="p-4 rounded-lg mt-6 w-full sm:w-fit sm:mr-4 bg-content-600"
            >
              <div class="flex space-x-2 items-center">
                <p class="font-semibold">
                  {{ property.name }}
                </p>

                <button
                  class="button-interactive"
                  @click="
                    propertyToUpdate = property;
                    showProperty = true;
                  "
                >
                  <edit-icon class="fill-current w-4" />
                </button>

                <button
                  class="button-interactive delete-hover"
                  @click="
                    propertyToDelete = property;
                    openDeleteModal();
                  "
                >
                  <trashcan-icon class="fill-current w-4" />
                </button>
              </div>

              <p class="text-content-100 text-sm">
                {{ property.squareMeters }} m2
              </p>
              <p v-if="property.hasInvestment" class="text-success-500 text-sm">
                Vendido
              </p>
            </div>
          </div>
        </div>

        <p class="mt-6">Publicaciones</p>

        <button class="mt-6 secondary-button" @click="showPost = true">
          + Crear Publicación
        </button>

        <div
          v-for="post in project.posts"
          :key="post.id"
          class="w-full bg-gradient-to-r from-content-800 to-content-700 p-4 rounded-lg mt-6"
        >
          <div class="flex space-x-2 items-center">
            <p>{{ post.title }}</p>

            <button
              class="button-interactive"
              @click="
                postToUpdate = post;
                showPost = true;
              "
            >
              <edit-icon class="fill-current w-4" />
            </button>

            <button
              class="button-interactive delete-hover"
              @click="
                postToDelete = post;
                openDeleteModal();
              "
            >
              <trashcan-icon class="fill-current w-4" />
            </button>
          </div>

          <p class="mt-1 text-xs text-content-100">{{ post.content }}</p>

          <div
            v-if="post.type === 'image'"
            class="bg-contain bg-no-repeat w-auto h-96 bg-center rounded-b mt-2"
            v-bind:style="{
              backgroundImage: 'url(' + post.src + ')',
            }"
          />

          <iframe
            v-else-if="post.type === 'video'"
            class="w-full h-96 bg-black min-w-full rounded-b mt-2"
            :src="post.src"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <p class="mt-2 text-xs text-content-200">
            {{ formatDate(post.createdAt) }}
          </p>
        </div>

        <button
          class="mt-6 delete-button"
          @click="
            deleteProject = true;
            openDeleteModal();
          "
        >
          Eliminar Proyecto
        </button>
      </div>
    </div>
    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import api from "../../../../api";
import DBString from "../../../../components/db-resources/String";
import BlockForm from "../../../../components/modals/forms/Block";
import PropertyForm from "../../../../components/modals/forms/Property";
import PostForm from "../../../../components/modals/forms/Post";
import deleteMixin from "../../../../mixins/deleteMixin";
export default {
  name: "Project Detail",

  components: {
    DBString,
    BlockForm,
    PropertyForm,
    PostForm,
  },

  data() {
    return {
      project: null,
      loading: false,
      loadingDelete: false,
      loadingImageCreate: false,
      loadingImageDelete: false,
      showBlock: false,
      showProperty: false,
      selectedBlock: null,
      blockToUpdate: null,
      blockToDelete: null,
      propertyToUpdate: null,
      propertyToDelete: null,
      deleteProject: false,
      deleteImage: false,
      imagePreview: null,
      newImage: null,
      showBlock: false,
      showPost: false,
      postToUpdate: null,
      postToDelete: null,
    };
  },

  mixins: [deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "adminProjects");

    this.loadProject();
  },

  methods: {
    async loadProject() {
      this.loading = true;

      try {
        this.project = await api.projects.getById(this.$route.params.id);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
        this.$router.push({ name: "adminProjectsIndex" });
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    close() {
      this.blockToDelete = null;
      this.propertyToDelete = null;
      this.postToDelete = null;
      this.deleteProject = false;
      this.closeDeleteModal();
    },

    async deleteElement() {
      this.loadingDelete = true;

      if (this.blockToDelete) {
        try {
          const response = await api.blocks.delete(this.blockToDelete.id);
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.blockToDelete = null;
          this.loadProject();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.propertyToDelete) {
        try {
          const response = await api.properties.delete(
            this.propertyToDelete.id
          );
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.propertyToDelete = null;
          this.loadProject();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.postToDelete) {
        try {
          const response = await api.posts.delete(this.postToDelete.id);
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.postToDelete = null;
          this.loadProject();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.deleteImage) {
        try {
          const response = await api.projects.deleteImage(this.project.id);
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.deleteImage = false;
          this.imagePreview = null;
          this.newImage = null;
          this.loadProject();
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      } else if (this.deleteProject) {
        try {
          const response = await api.projects.delete(this.project.id);
          this.$store.dispatch("successSnack", response.message);
          this.closeDeleteModal();
          this.deleteProject = false;
          this.$router.push({ name: "adminProjectsIndex" });
        } catch (error) {
          this.$store.dispatch("errorSnack", error?.response?.data?.message);
        }
      }

      this.loadingDelete = false;
    },

    getImage(e) {
      let image = e.target.files[0];
      if (image) {
        let url = URL.createObjectURL(image);
        let img = new Image();

        img.onload = () => {
          URL.revokeObjectURL(url);
          let reader = new FileReader();
          reader.onload = (e) => {
            this.newImage = image;
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(image);
        };

        img.onerror = () => {
          URL.revokeObjectURL(url);
          alert("Unable to load image");
        };

        img.src = url;
      }
    },

    async storeImage() {
      this.loadingImageCreate = true;

      const formData = new FormData();

      formData.append("image", this.newImage);

      try {
        const response = await api.projects.createImage(
          this.project.id,
          formData
        );
        this.$store.dispatch("successSnack", response.message);
        this.loadProject();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingImageCreate = false;
    },
  },
};
</script>
<template>
  <div class="flex flex-col absolute inset-0 bg-content-900">
    <router-view class="bg-background-500" />

    <transition name="snack">
      <snack v-if="snack" :snack="snack" />
    </transition>
  </div>
</template>

<script>
import Snack from "./components/ui/Snack";
import { mapState } from "vuex";
export default {
  name: "App",

  components: {
    Snack,
  },

  computed: {
    ...mapState({
      snack: "snack",
    }),
  },
};
</script>

<style >
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.snack-enter-active,
.snack-leave-active {
  transition: all 0.3s ease;
}
.snack-enter-from,
.snack-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
</style>
<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-50 bg-black"
  >
    <div class="modal">
      <form>
        <div class="flex flex-col w-full mt-2">
          <input-label for="name" value="Nombre"></input-label>
          <input class="w-full" id="name" type="text" v-model="form.name" />
          <validation-errors :errors="errorFor('name')"></validation-errors>
        </div>

        <div class="flex flex-col w-full mt-2">
          <input-label for="squareMeters" value="Metros cuadrados"></input-label>
          <input class="w-full" id="squareMeters" type="number" v-model="form.squareMeters" />
          <validation-errors :errors="errorFor('squareMeters')"></validation-errors>
        </div>

        <primary-button
          class="mt-12 w-full"
          :loading="loading"
          @buttonClicked="save"
          >{{ !isEditing ? "Crear" : "Editar" }} Lote</primary-button
        >
      </form>

      <button class="link mt-4 text-center" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
export default {
  name: "Property Form",

  data() {
    return {
      form: {
        name: null,
        blockId: null,
      },
      loading: false,
      isEditing: false,
    };
  },

  props: {
    blockId: Number,
    property: Object
  },

  mixins: [validationErrors],

  created() {
    if (this.property) {
      this.isEditing = true;
      this.form = JSON.parse(JSON.stringify(this.property));
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },

    validateForm() {
      this.errors = {};

      if (!this.form.name) {
        this.errors["name"] = ["Campo requerido"];
      }

      if (!this.form.squareMeters) {
        this.errors["squareMeters"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      if (!this.isEditing) {
        this.createProperty();
      } else {
        this.updateProperty();
      }
    },

    async createProperty() {
      this.form.blockId = this.blockId;

      this.loading = true;

      try {
        const response = await api.properties.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },

    async updateProperty() {
      this.loading = true;

      try {
        const response = await api.properties.update(this.property.id, this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
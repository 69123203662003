export default {
  path: '/auth',
  apiClient: null,

  setClient(client) {
    this.apiClient = client;
  },

  async login(form) {
    try {
      const response = await this.apiClient.post(`${this.path}/login`, form);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async me() {
    try {
      const response = await this.apiClient.get(`${this.path}/me`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
<template>
  <div class="flex flex-col sm:flex-row h-full justify-center items-center relative">
    <transition name="modal">
      <login v-if="showLogin" @close="showLogin = false" />
    </transition>

    <div class="w-3/4 sm:w-1/2 flex flex-col">
      <h1 class="w-full text-3xl sm:text-7xl text-white font-bold text-center">
        Growth Investments
      </h1>
      <h1 class="w-full text-xl sm:text-4xl text-content-100 font-thin text-center mt-8">
        Administra tus inversiones
      </h1>
      <primary-button class="mt-8 mx-auto" @buttonClicked="login"
        >Ingresar</primary-button
      >
    </div>

    <div class="w-3/4 sm:w-1/2 mt-4 sm:mt-0">
      <img
        class="w-full rounded-full"
        :src="require('@/assets/landing.png')"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Login from "../components/modals/Login";
import validationErrors from "../mixins/validationErrors";
import { mapState } from "vuex";
export default {
  components: {
    Login,
  },

  data() {
    return {
      showLogin: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    ...mapState({
      user: "user",
    }),
  },

  methods: {
    login() {
      if (this.user) {
        this.$router.push({ name: this.user.type === 'admin' ? 'adminClientsIndex' : 'investments' });
      } else {
        this.showLogin = true;
      }
    },
  },
};
</script>

<style scoped>
.login-enter-active,
.login-leave-active {
  transition: all 0.3s ease;
}
.login-enter-from,
.login-leave-to {
  transform: translateY(50px);
}
</style>
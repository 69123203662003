import { createStore } from 'vuex'
import { isLoggedIn, logIn, logOut } from "../utils/auth";
import api from "../api";
import router from "../router";

export default createStore({
  state: {
    user: null,
    selectedTab: 'investments',
    previousProjectRoute: 'projectsIndex',
    snack: null,
  },
  getters: {
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },

    setSelectedTab(state, payload) {
      state.selectedTab = payload;
    },

    setPreviousProjectRoute(state, payload) {
      state.previousProjectRoute = payload;
    },

    setSnack(state, payload) {
      state.snack = payload;
      setTimeout(() => (state.snack = null), 3000);
    },
  },
  actions: {
    login({ commit }, payload) {
      logIn(payload.token);
      commit("setUser", payload.user);
    },

    async loadUser({ commit, dispatch }) {
      if (isLoggedIn()) {
        try {
          const user = await api.auth.me();
          commit("setUser", user);
        } catch (error) {
          if (error.response.status !== 401) {
            dispatch("logout");
          }
        }
      }
      return Promise.resolve();
    },

    logout({ commit, state }) {
      commit("setUser", null);
      logOut();
      router.push({ name: 'landing' });
    },

    errorSnack({ commit }, payload) {
      commit("setSnack", {
        message: payload,
        type: "error",
      });
    },

    successSnack({ commit }, payload) {
      commit("setSnack", {
        message: payload,
        type: "success",
      });
    },
  },
  modules: {
  }
})

<template>
  <div>
    <transition name="modal">
      <delete
        v-if="showDeleteModal"
        :loading="loadingDelete"
        @close="closeDeleteModal()"
        @confirmDelete="deleteElement"
      ></delete>
    </transition>

    <div v-if="!loading">
      <div v-if="client" class="flex flex-col">
        <div class="flex items-center space-x-2">
          <router-link
            :to="{ name: 'adminClientsIndex' }"
            class="fill-current button-interactive"
          >
            <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
          </router-link>
        </div>

        <p class="mt-4">{{ client.name }} {{ client.lastName }}</p>

        <d-b-string
          class="mt-4"
          field="name"
          :value="client.name"
          title="Nombre(s)"
          :id="client.id"
          model="client"
          :resource="{ api: 'clients', name: 'updateName' }"
          :active="true"
          @updateName="client.name = $event"
        />

        <d-b-string
          class="mt-4"
          field="lastName"
          :value="client.lastName"
          title="Apellido(s)"
          :id="client.id"
          model="client"
          :resource="{ api: 'clients', name: 'updateLastName' }"
          :active="true"
          @updateLastName="client.lastName = $event"
        />

        <d-b-string
          class="mt-4"
          field="phone"
          :value="client.phone"
          title="Teléfono"
          :id="client.id"
          model="client"
          :resource="{ api: 'clients', name: 'updatePhone' }"
          :active="true"
          @updatePhone="client.phone = $event"
        />

        <d-b-string
          class="mt-4"
          field="email"
          :value="client.user.email"
          title="Correo"
          :id="client.id"
          model="client"
          :resource="{ api: 'clients', name: 'updateEmail' }"
          :active="true"
          @updateEmail="client.user.email = $event"
        />

        <d-b-string
          class="mt-4"
          type="password"
          field="password"
          title="Contraseña"
          :id="client.id"
          model="client"
          :resource="{ api: 'clients', name: 'updatePassword' }"
          :active="true"
          @updatePassword="client.user.password = null"
        />

        <div class="w-full border-t border-primary-500 mt-6 py-6">
          <h2 class="text-lg">Inversiones inmbobiliarias</h2>

          <!-- <router-link
        class="primary-button mt-6"
        :to="{ name: 'adminClientsPropertyInvestmentForm' }"
      >
        Agregar Inversión
      </router-link> -->

          <table
            class="w-full table-fixed text-sm rounded-lg shadow-primary mt-6"
          >
            <thead class="text-left">
              <tr class="text-left">
                <th class="p-2">Id</th>
                <th class="p-2">Lote</th>
                <th class="p-2">Projecto</th>
                <th class="p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="investment in client.propertyInvestments"
                :key="investment.id"
                class="text-white"
              >
                <td class="p-2 border-t">{{ investment.id }}</td>
                <td class="p-2 border-t">{{ investment.property.name }}</td>
                <td class="p-2 border-t">
                  {{ investment.property.block.project.name }}
                </td>
                <td class="p-2 border-t">
                  <router-link
                    class="button-interactive p-1 border rounded text-xs hover:border-primary-500"
                    :to="{
                      name: 'adminInvestmentsPropertyDetails',
                      params: { id: investment.id },
                    }"
                  >
                    Detalle
                    <!-- <details-icon class="w-5 fill-current stroke-current" /> -->
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="w-full border-t border-primary-500 mt-6 py-6">
          <h2 class="text-lg">Inversiones por rendimiento</h2>

          <table
            class="w-full table-fixed text-sm rounded-lg shadow-primary mt-6"
          >
            <thead class="text-left">
              <tr class="text-left">
                <th class="p-2">Id</th>
                <th class="p-2">Nombre</th>
                <th class="p-2">Plazo en meses</th>
                <th class="p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="investment in client.stagedInvestments"
                :key="investment.id"
                class="text-white"
              >
                <td class="p-2 border-t">{{ investment.id }}</td>
                <td class="p-2 border-t">
                  {{ investment.name }}
                </td>
                <td class="p-2 border-t">
                  {{ investment.timeLapse }}
                </td>
                <td class="p-2 border-t">
                  <router-link
                    class="button-interactive p-1 border rounded text-xs hover:border-primary-500"
                    :to="{
                      name: 'adminInvestmentsStagedDetails',
                      params: { id: investment.id },
                    }"
                  >
                    Detalle
                    <!-- <details-icon class="w-5 fill-current stroke-current" /> -->
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <button class="mt-6 delete-button" @click="openDeleteModal()">
          Eliminar Cliente
        </button>
      </div>
    </div>

    <div v-else class="flex w-full h-full items-center">
      <loader-icon class="h-10 w-10 text-primary-500 mx-auto"></loader-icon>
    </div>
  </div>
</template>

<script>
import api from "../../../../api";
import DBString from "../../../../components/db-resources/String";
import deleteMixin from "../../../../mixins/deleteMixin";
import { LineChart, DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  name: "Client Details",

  components: {
    DBString,
    LineChart,
    DoughnutChart,
  },

  data() {
    return {
      client: null,
      loading: false,
      loadingDelete: false,
    };
  },

  computed: {
    chartOptions() {
      return {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const tooltipItem = context.parsed;
                const dataset = context.dataset;
                const reason = dataset.reasons[context.dataIndex];
                return `Precio del m2: ${tooltipItem.y} - Razón: ${reason}`;
              },
            },
          },
        },
      };
    },

    doughnutChartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        cutout: "70%",
      };
    },
  },

  mixins: [deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "adminClients");

    this.loadClient();
  },

  methods: {
    async loadClient() {
      this.loading = true;

      try {
        this.client = await api.clients.getById(this.$route.params.id);
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
        this.$router.push({ name: "adminClientsIndex" });
      }

      this.loading = false;
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    integerToCurrency(amount) {
      if (!amount) {
        return "-";
      }

      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    getCurrentValue(investment) {
      return this.integerToCurrency(
        investment.investmentValues[investment.investmentValues.length - 1]
          ? investment.investmentValues[investment.investmentValues.length - 1]
              .value
          : null
      );
    },

    prepareChartData(investment) {
      const reasons = investment.investmentValues.map((entry) => entry.reason);
      return {
        labels: investment.investmentValues.map((entry) =>
          new Date(entry.createdAt).toLocaleDateString()
        ),
        datasets: [
          {
            label: "Precio del m2",
            data: investment.investmentValues.map(
              (entry) => entry.squareMeterPrice
            ),
            borderColor: "#028cf3",
            fill: false,
            reasons: investment.investmentValues.map((entry) => entry.reason),
          },
        ],
      };
    },

    getTotalPaid(payments) {
      return payments.reduce((sum, payment) => sum + payment.amount, 0);
    },

    prepareDoughnutChartData(investment) {
      const totalPaid = this.getTotalPaid(investment.payments);
      const remaining = investment.totalAmount - totalPaid;

      return {
        labels: ["Pagado", "Restante"],
        datasets: [
          {
            data: [totalPaid, remaining],
            backgroundColor: ["#2feaa8", "#0b0d0e"],
            borderWidth: 0,
          },
        ],
      };
    },

    async deleteElement() {
      this.loadingDelete = true;

      try {
        const response = await api.clients.delete(this.client.id);
        this.$store.dispatch("successSnack", response.message);
        this.closeDeleteModal();
        this.$router.push({ name: "adminClientsIndex" });
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingDelete = false;
    },
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.2s ease-in-out;
}
.expand-enter-from, .expand-leave-to /* .expand-leave-active in <2.1.8 */ {
  width: 0;
  left: 50%;
}
.expand-enter-to, .expand-leave-from /* .expand-leave-active in <2.1.8 */ {
  width: 100%;
  left: 0;
}
</style>
<template>
  <div
    class="fixed p-4 rounded-b-lg z-50 top-0 left-0 right-0 shadow-primary text-white"
    :class="[
      { 'bg-green-500': snack.type === 'success' },
      { 'bg-red-500': snack.type === 'error' },
    ]"
  >
    {{ snack.message }}
  </div>
</template>

<script>
export default {
  props: {
    snack: Object,
  },
};
</script>
<template>
  <div>
    <div class="flex items-center">
      <router-link
        :to="{ name: 'adminInvestmentsIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <form class="">
      <div class="flex flex-wrap">
        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="client" value="Cliente" />
          <select
            class="border py-2 px-3 rounded-lg outline-none smooth focus:border-primary-500 smooth w-full bg-transparent"
            id="client"
            v-model="form.clientId"
          >
            <option :value="null" selected>Seleccionar</option>
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
            >
              {{ client.name }} {{ client.lastName }}
            </option>
          </select>
          <validation-errors :errors="errorFor('client')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="name" value="Nombre"></input-label>
          <input class="" id="name" type="text" v-model="form.name" />
          <validation-errors :errors="errorFor('name')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="timeLapse" value="Período de inversión (meses)"></input-label>
          <input
            class=""
            id="timeLapse"
            type="number"
            v-model="form.timeLapse"
          />
          <validation-errors
            :errors="errorFor('timeLapse')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="initialAmountInvested"
            value="Monto de inversión"
          ></input-label>
          <input
            class=""
            id="initialAmountInvested"
            type="number"
            v-model="form.initialAmountInvested"
          />
          <validation-errors
            :errors="errorFor('initialAmountInvested')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="purchaseDate" value="Fecha de inversión"></input-label>
          <input
            class=""
            id="purchaseDate"
            type="datetime-local"
            v-model="form.purchaseDate"
          />
          <validation-errors
            :errors="errorFor('purchaseDate')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="saleDate" value="Fecha de liberación"></input-label>
          <input class="" id="saleDate" type="datetime-local" v-model="form.saleDate" />
          <validation-errors :errors="errorFor('saleDate')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="investmentReturn"
            value="Retorno de inversión"
          ></input-label>
          <input
            class=""
            id="investmentReturn"
            type="number"
            v-model="form.investmentReturn"
          />
          <validation-errors
            :errors="errorFor('investmentReturn')"
          ></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label
            for="totalReleased"
            value="Inversión total liberada"
          ></input-label>
          <input
            class=""
            id="totalReleased"
            type="number"
            v-model="form.totalReleased"
          />
          <validation-errors
            :errors="errorFor('totalReleased')"
          ></validation-errors>
        </div>
      </div>

      <primary-button
        class="mt-12 w-full sm:w-96"
        :loading="loading"
        @buttonClicked="createInvestment"
        >Crear Inversion</primary-button
      >
    </form>
  </div>
</template>

<script>
import api from "../../../../../api";
import validationErrors from "../../../../../mixins/validationErrors";
import { is422 } from "../../../../../utils/response";
export default {
  name: "Staged Investments Form",

  data() {
    return {
      clients: null,
      form: {
        clientId: null,
        name: null,
        timeLapse: null,
        initialAmountInvested: null,
        purchaseDate: null,
        saleDate: null,
        investmentReturn: null,
        totalReleased: null
      },
      loading: false,
    };
  },

  mixins: [validationErrors],

  created() {
    this.$store.commit("setSelectedTab", "adminInvestments");

    this.loadClients();
  },

  methods: {
    async loadClients() {
      try {
        this.clients = await api.clients.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    validateForm() {
      this.errors = {};

      if (!this.form.clientId) {
        this.errors["client"] = ["Campo requerido"];
      }

      return Object.keys(this.errors).length === 0;
    },

    async createInvestment() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.stagedInvestments.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "adminInvestmentsIndex" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
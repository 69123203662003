<template>
  <div>
    <h2 class="text-lg mb-8">Archivos</h2>

    <div class="flex flex-wrap">
      <div
        v-for="file in files"
        :key="file.id"
        class="p-4 rounded-lg w-full sm:w-fit sm:mr-4 bg-content-600"
      >
        <div class="flex justify-between items-center">
          <p class="text-content-200 text-semibold font-light">
            {{ file.title }}
          </p>
          <button class="button-interactive">
            <downloadIcon class="w-4 fill-current stroke-current" />
          </button>
        </div>

        <p class="text-xs text-content-200 text-semibold font-light mx-t2">
          {{ formatDate(file.timestamp) }}
        </p>
      </div>
    </div>

    <h2 class="text-lg my-8">Publicaciones</h2>

    <div class="">
      <div
        v-for="post in posts"
        :key="post.id"
        class="flex flex-col rounded-lg mb-6 bg-gradient-to-r from-content-800 to-content-700"
      >
        <p class="text-xs font-light mt-6 mx-6">
          {{ postTime(post.createdAt) }}
        </p>
        <p class="text-white my-4 mx-6">{{ post.text }}</p>
        <div
          v-if="post.type === 'image'"
          class="bg-cover bg-no-repeat w-auto h-96 bg-center rounded-b"
          v-bind:style="{
            backgroundImage: 'url(' + post.url + ')',
          }"
        />

        <iframe
          v-else-if="post.type === 'video'"
          class="w-full h-96 bg-black min-w-full rounded-b"
          :src="post.url"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from "../../../icons/ArrowIcon";
import DownloadIcon from "../../../icons/DownloadIcon";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Project Detail",

  components: {
    ArrowIcon,
    DownloadIcon,
  },

  data() {
    return {
      files: [
        {
          id: 1,
          title: "Plano",
          timestamp: "2023-05-15T12:00:00",
        },
        {
          id: 2,
          title: "Escrituras",
          timestamp: "2023-05-15T12:00:00",
        },
      ],
      posts: [
        {
          id: 1,
          type: "image",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, placeat id. Laborum voluptates perferendis, ab ducimus eius magni enim, quia delectus repellat quod temporibus voluptatibus. Ad fugiat et placeat fuga.",
          url: require("@/assets/la_joya.jpeg"),
          createdAt: "2023-08-14T12:00:00",
        },
        {
          id: 2,
          type: "video",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, placeat id. Laborum voluptates perferendis, ab ducimus eius magni enim, quia delectus repellat quod temporibus voluptatibus. Ad fugiat et placeat fuga.",
          url: "https://www.youtube.com/embed/A16elkJVcaA",
          createdAt: "2023-08-14T12:00:00",
        },
        {
          id: 3,
          type: "text",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, placeat id. Laborum voluptates perferendis, ab ducimus eius magni enim, quia delectus repellat quod temporibus voluptatibus. Ad fugiat et placeat fuga.",
          createdAt: "2023-08-14T12:00:00",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      previousProjectRoute: "previousProjectRoute",
    }),
  },

  created() {
    this.$store.commit("setSelectedTab", "financialAdvice");
  },

  methods: {
    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    integerToCurrency(amount) {
      const formatter = new Intl.NumberFormat({
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
      });

      return "$" + formatter.format(amount) + "MXN";
    },

    postTime(date) {
      if (!date) {
        return "Sin hora asignada";
      }

      return moment(date).locale("es").format("LLL");
    },
  },
};
</script>
import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";

import Landing from "../views/Landing";
import Test from "../views/Test";

// Admin Tabs

import AdminDashboard from "../views/tabs/admin/Dashboard";

// Clients
import AdminClientsIndex from "../views/tabs/admin/clients/Index";
import AdminClientsForm from "../views/tabs/admin/clients/Form";
import AdminClientsDetails from "../views/tabs/admin/clients/Details";

// Property Investments
import AdminInvestmentsIndex from "../views/tabs/admin/investments/Index";
import AdminInvestmentsPropertyForm from "../views/tabs/admin/investments/propertyInvestments/Form";
import AdminInvestmentsPropertyDetails from "../views/tabs/admin/investments/propertyInvestments/Details";
import AdminInvestmentsStagedForm from "../views/tabs/admin/investments/stagedInvestments/Form";
import AdminInvestmentsStagedDetails from "../views/tabs/admin/investments/stagedInvestments/Details";

// Projects
import AdminProjectsIndex from "../views/tabs/admin/projects/Index";
import AdminProjectsForm from "../views/tabs/admin/projects/Form";
import AdminProjectsDetails from "../views/tabs/admin/projects/Details";

// Opportunities
import AdminOpportunitiesIndex from "../views/tabs/admin/opportunities/Index";
import AdminOpportunitiesForm from "../views/tabs/admin/opportunities/Form";

// Client Tabs
import ClientDashboard from "../views/tabs/client/Dashboard";
import Investments from "../views/tabs/client/Investments";
import Opportunities from "../views/tabs/client/Opportunities";
import ProjectIndex from "../views/tabs/client/projects/Index";
import ProjectDetail from "../views/tabs/client/projects/Detail";
import FinancialAdvice from "../views/tabs/client/FinancialAdvice";

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/dashboard',
    name: 'clientDashboard',
    component: ClientDashboard,
    meta: { requiresLogin: true, requiresClient: true },
    children: [
      {
        path: '/',
        children: [
          {
            path: '/inversiones',
            name: 'investments',
            component: Investments
          },
          {
            path: '/oportunidades',
            name: 'opportunities',
            component: Opportunities
          },
          {
            path: '/proyectos',
            name: 'projects',
            redirect: { name: 'projectsIndex' },
            children: [
              {
                path: '',
                name: 'projectsIndex',
                component: ProjectIndex
              },
              {
                path: ':id',
                name: 'projectsDetail',
                component: ProjectDetail
              }
            ]
          },
          {
            path: '/informacion-financiera',
            name: 'financialAdvice',
            component: FinancialAdvice
          },
        ]
      }
    ]
  },
  {
    path: '/admin/dashboard',
    name: 'adminDashboard',
    component: AdminDashboard,
    meta: { requiresLogin: true, requiresAdmin: true },
    redirect: { name: 'adminClientsIndex' },
    children: [
      {
        path: 'clientes',
        name: 'adminClients',
        redirect: { name: 'adminClientsIndex' },
        children: [
          {
            path: '',
            name: 'adminClientsIndex',
            component: AdminClientsIndex
          },
          {
            path: 'crear',
            name: 'adminClientsForm',
            component: AdminClientsForm
          },
          {
            path: ':id',
            name: 'adminClientsDetails',
            component: AdminClientsDetails
          }
        ]
      },
      {
        path: 'inversiones',
        name: 'adminInvestments',
        redirect: { name: 'adminInvestmentsIndex' },
        children: [
          {
            path: '',
            name: 'adminInvestmentsIndex',
            component: AdminInvestmentsIndex
          },
          {
            path: 'inmobiliarias/crear',
            name: 'adminInvestmentsPropertyForm',
            component: AdminInvestmentsPropertyForm
          },
          {
            path: 'inmobiliarias/:id',
            name: 'adminInvestmentsPropertyDetails',
            component: AdminInvestmentsPropertyDetails
          },
          {
            path: 'rendimiento/crear',
            name: 'adminInvestmentsStagedForm',
            component: AdminInvestmentsStagedForm
          },
          {
            path: 'rendimiento/:id',
            name: 'adminInvestmentsStagedDetails',
            component: AdminInvestmentsStagedDetails
          }
        ]
      },
      {
        path: 'proyectos',
        name: 'adminProjects',
        redirect: { name: 'adminProjectsIndex' },
        children: [
          {
            path: '',
            name: 'adminProjectsIndex',
            component: AdminProjectsIndex
          },
          {
            path: 'crear',
            name: 'adminProjectsForm',
            component: AdminProjectsForm
          },
          {
            path: ':id',
            name: 'adminProjectsDetails',
            component: AdminProjectsDetails
          }
        ]
      },
      {
        path: 'oportunidades',
        name: 'adminOpportunities',
        redirect: { name: 'adminOpportunitiesIndex' },
        children: [
          {
            path: '',
            name: 'adminOpportunitiesIndex',
            component: AdminOpportunitiesIndex
          },
          {
            path: 'crear',
            name: 'adminOpportunitiesForm',
            component: AdminOpportunitiesForm
          },
          {
            path: 'editar/:id',
            name: 'adminOpportunitiesEditForm',
            component: AdminOpportunitiesForm
          }
        ]
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && !store.state.user) {
    next({ name: 'landing' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresAdmin) && store.state.user.type !== 'admin') {
    console.log("admin");
    next({ name: 'investments' });
    return;
  }

  if (to.matched.some(record => record.meta.requiresClient) && store.state.user.type !== 'client') {
    next({ name: 'adminClientsIndex' });
    return;
  }

  next();
});

export default router

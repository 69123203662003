<template>
  <div class="flex flex-col">
    <transition name="modal">
      <delete
        v-if="showDeleteModal"
        :loading="loadingDelete"
        @close="close"
        @confirmDelete="deleteElement"
      ></delete>
    </transition>

    <router-link
      class="primary-button"
      :to="{ name: 'adminOpportunitiesForm' }"
    >
      Agregar Oportunidad
    </router-link>

    <div
      v-for="opportunity in opportunities"
      :key="opportunity.id"
      class="w-full bg-gradient-to-r from-content-800 to-content-700 p-4 rounded-lg mt-6"
    >
      <div class="flex space-x-2 items-center">
        <p>{{ opportunity.title }}</p>

        <router-link
          :to="{
            name: 'adminOpportunitiesEditForm',
            params: { id: opportunity.id },
          }"
          class="button-interactive"
        >
          <edit-icon class="fill-current w-4" />
        </router-link>

        <button
          class="button-interactive delete-hover"
          @click="
            opportunityToDelete = opportunity;
            openDeleteModal();
          "
        >
          <trashcan-icon class="fill-current w-4" />
        </button>
      </div>

      <p class="mt-1 text-xs text-content-100">{{ opportunity.content }}</p>

      <div
        v-if="opportunity.type === 'image'"
        class="bg-contain bg-no-repeat w-auto h-96 bg-center rounded-b mt-2"
        v-bind:style="{
          backgroundImage: 'url(' + opportunity.src + ')',
        }"
      />

      <iframe
        v-else-if="opportunity.type === 'video'"
        class="w-full h-96 bg-black min-w-full rounded-b mt-2"
        :src="opportunity.src"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <p class="mt-2 text-xs text-content-200">
        {{ formatDate(opportunity.createdAt) }}
      </p>
    </div>
  </div>
</template>

<script>
import api from "../../../../api";
import deleteMixin from "../../../../mixins/deleteMixin";
export default {
  name: "Opportunities Index",

  data() {
    return {
      opportunities: null,
      opportunityToUpdate: null,
    };
  },

  mixins: [deleteMixin],

  created() {
    this.$store.commit("setSelectedTab", "adminOpportunities");

    this.loadOpportunitiess();
  },

  methods: {
    async loadOpportunitiess() {
      try {
        this.opportunities = await api.opportunities.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },

    formatDate(datetime) {
      if (!datetime) {
        return "-";
      }

      const date = new Date(datetime);
      return date.toLocaleString("en-US");
    },

    close() {
      this.opportunityToDelete = null;
      this.closeDeleteModal();
    },

    async deleteElement() {
      this.closeDeleteModal();

      this.loadingDelete = true;

      try {
        const response = await api.opportunities.delete(
          this.opportunityToDelete.id
        );
        this.$store.dispatch("successSnack", response.message);
        this.opportunityToDelete = null;
        this.loadOpportunitiess();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loadingDelete = false;
    },
  },
};
</script>
<template>
  <label>
    <span class="text-content-100 text-xs sm:text-sm" v-if="value">{{ value }}</span>
    <span v-if="required" class="text-red-500">*</span>
    <span class="block text-xs font-medium text-content-100" v-if="details">{{ details }}</span>
  </label>
</template>

<script>
export default {
  props: {
    value: String,
    required: Boolean,
    details: Text
  }
};
</script>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

// UI Components
import PrimaryButton from "./components/ui/PrimaryButton";
import InputLabel from "./components/ui/InputLabel";

// Icons
import LoaderIcon from "./icons/LoaderIcon";
import CloseIcon from "./icons/CloseIcon";
import EditIcon from "./icons/EditIcon";
import TrashcanIcon from "./icons/TrashcanIcon";
import DetailsIcon from "./icons/DetailsIcon";
import BackIcon from "./icons/BackIcon";
import CheckIcon from "./icons/CheckIcon";
import ArrowIcon from "./icons/ArrowIcon";

const app = createApp(App);

app.component('primary-button', PrimaryButton);
app.component('input-label', InputLabel);
app.component('loader-icon', LoaderIcon);
app.component('close-icon', CloseIcon);
app.component('edit-icon', EditIcon);
app.component('trashcan-icon', TrashcanIcon);
app.component('details-icon', DetailsIcon);
app.component('back-icon', BackIcon);
app.component('check-icon', CheckIcon);
app.component('arrow-icon', ArrowIcon);

app.use(store);

(async () => {
  await store.dispatch("loadUser");

  app.use(router);

  app.mount('#app');
})();

<template>
  <div
    class="flex flex-col justify-center items-center fixed z-40 inset-0 bg-opacity-50 bg-black"
  >
    <div class="modal">
      <form>
        <div class="flex flex-col w-full mt-2">
          <input-label for="description" value="Descripción"></input-label>
          <textarea class="w-full h-24" id="description" type="text" v-model="description" />
          <validation-errors :errors="errorFor('description')"></validation-errors>
        </div>

        <div class="flex items-center mt-4">
          <div v-if="!newFile" class="w-full">
            <input
              class="hidden"
              type="file"
              name="file"
              id="file"
              ref="file"
              accept="file/*"
              @change="handleFile"
            />

            <label for="file" class="cursor-pointer secondary-button"
              >Adjuntar Archivo</label
            >

            <validation-errors :errors="errorFor('file')"></validation-errors>
          </div>

          <div v-else class="flex items-center space-x-2">
            <p class="text-xs">{{ fileName }}</p>
            <button
              v-if="!loading"
              class="button-interactive delete-hover"
              @click.prevent="
                fileName = null;
                newFile = null;
              "
            >
              <close-icon class="w-4 fill-current" />
            </button>
          </div>
        </div>

        <primary-button
          class="mt-12 w-full"
          :loading="loading"
          @buttonClicked="save"
          >Agregar Archivo</primary-button
        >
      </form>

      <button class="link mt-4 text-center" @click.prevent="close">
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api";
import validationErrors from "../../../mixins/validationErrors";
import { is422 } from "../../../utils/response";
export default {
  name: "File Form",

  data() {
    return {
      loading: false,
      newFile: null,
      fileName: null,
      description: "",
    };
  },

  props: {
    opportunityId: Number,
  },

  mixins: [validationErrors],

  methods: {
    close() {
      this.$emit("close");
    },

    handleFile(e) {
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      this.newFile = file;
      this.fileName = file.name;
    },

    validateForm() {
      this.errors = {};

      if (!this.description) {
        this.errors["description"] = ["Campo requerido"];
      }

      if (!this.newFile) {
        this.errors["file"] = ["Es necesario agregar un archivo"];
      }

      return Object.keys(this.errors).length === 0;
    },

    save() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.createFile();
    },

    async createFile() {
      this.loading = true;

      const formData = new FormData();
      formData.append("description", this.description);
      formData.append("opportunityId", this.opportunityId);
      formData.append("file", this.newFile);

      try {
        const response = await api.opportunities.addFile(formData);
        this.$store.dispatch("successSnack", response.message);
        this.$emit("confirm");
        this.close();
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
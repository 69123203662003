<template>
  <div>
    <div class="flex items-center">
      <router-link
        :to="{ name: 'adminClientsIndex' }"
        class="fill-current button-interactive"
      >
        <arrow-icon class="w-3 sm:w-4 fill-current rotate-90"></arrow-icon>
      </router-link>
    </div>

    <form class="">
      <div class="flex flex-wrap">
        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="name" value="Nombre(s)"></input-label>
          <input class="" id="name" type="text" v-model="form.name" />
          <validation-errors :errors="errorFor('name')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="lastName" value="Apellido(s)"></input-label>
          <input class="" id="lastName" type="text" v-model="form.lastName" />
          <validation-errors :errors="errorFor('lastName')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="phone" value="Teléfono"></input-label>
          <input class="" id="phone" type="text" v-model="form.phone" />
          <validation-errors :errors="errorFor('phone')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <input-label for="email" value="Correo"></input-label>
          <input class="" id="email" type="email" v-model="form.email" />
          <validation-errors :errors="errorFor('email')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <div class="flex justify-between">
            <input-label for="password" value="Contraseña"></input-label>
            <button class="link" @click.prevent="showPassword = !showPassword">
              {{ passwordButtonText }}
            </button>
          </div>
          <input
            class=""
            id="password"
            :type="passwordType"
            v-model="form.password"
          />

          <password-validation
            class="mt-1"
            :password="form.password"
          ></password-validation>

          <validation-errors :errors="errorFor('password')"></validation-errors>
        </div>

        <div class="flex flex-col w-full sm:w-96 mt-2 sm:mr-4">
          <div class="flex justify-between">
            <input-label
              for="passwordConfirmation"
              value="Confirmar contraseña"
            ></input-label>
            <button
              class="link"
              @click.prevent="showConfirmPassword = !showConfirmPassword"
            >
              {{ confirmPasswordButtonText }}
            </button>
          </div>

          <input
            class=""
            id="passwordConfirmation"
            :type="confirmPasswordType"
            v-model="form.passwordConfirmation"
          />

          <password-validation
            class="mt-1"
            :password="form.password"
            :passwordConfirmation="form.passwordConfirmation"
            :confirmation="true"
          ></password-validation>

          <validation-errors
            :errors="errorFor('passwordConfirmation')"
          ></validation-errors>
        </div>
      </div>

      <primary-button
        class="mt-12 w-full sm:w-96"
        :loading="loading"
        @buttonClicked="createClient"
        >Crear Cliente</primary-button
      >
    </form>
  </div>
</template>

<script>
import PasswordValidation from "../../../../components/ui/PasswordValidation";
import api from "../../../../api";
import validationErrors from "../../../../mixins/validationErrors";
import { is422 } from "../../../../utils/response";
export default {
  name: "Client Form",

  components: {
    PasswordValidation,
  },

  data() {
    return {
      form: {
        name: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
      },
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },

  mixins: [validationErrors],

  computed: {
    passwordButtonText() {
      return this.showPassword ? "Ocultar" : "Mostrar";
    },

    passwordType() {
      return this.showPassword ? "text" : "password";
    },

    confirmPasswordButtonText() {
      return this.showConfirmPassword ? "Ocultar" : "Mostrar";
    },

    confirmPasswordType() {
      return this.showConfirmPassword ? "text" : "password";
    },
  },

  created() {
    this.$store.commit("setSelectedTab", "adminClients");
  },

  methods: {
    validateForm() {
      this.errors = {};

      this.validateEmail(this.form.email);
      this.validateRegisterPassword(this.form.password);
      this.validatePasswordConfirmation(
        this.form.password,
        this.form.passwordConfirmation
      );
      this.validateName(this.form.name);
      this.validateLastName(this.form.lastName);
      this.validatePhone(this.form.phone);

      return Object.keys(this.errors).length === 0;
    },

    async createClient() {
      if (!this.validateForm()) {
        this.$store.dispatch(
          "errorSnack",
          "Favor de llenar todos los campos correctamente."
        );
        return;
      }

      this.loading = true;

      try {
        const response = await api.clients.create(this.form);
        this.$store.dispatch("successSnack", response.message);
        this.$router.push({ name: "adminClientsIndex" });
      } catch (error) {
        if (is422(error)) {
          this.errors = error?.response?.data?.errors;
        }
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }

      this.loading = false;
    },
  },
};
</script>
<template>
  <div class="flex flex-col">
    <router-link
      class="primary-button"
      :to="{ name: 'adminProjectsForm' }"
    >
      Agregar Projecto
    </router-link>

    <div>
      <table
        v-if="projects"
        class="w-full table-auto text-sm rounded-lg shadow-primary mt-6"
      >
        <thead class="text-left">
          <tr class="text-left">
            <th class="p-2">Id</th>
            <th class="p-2">Nombre</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="project in projects"
            :key="project.id"
            class="text-white"
          >
            <td class="p-2 border-t">{{ project.id }}</td>
            <td class="p-2 border-t">{{ project.name }}</td>
            <td class="p-2 border-t">
              <router-link
                class="secondary-button"
                :to="{ name: 'adminProjectsDetails', params: { id: project.id } }"
              >
                Detalle
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from "../../../../api";
export default {
  name: "Projects Index",

  data() {
    return {
      projects: null
    };
  },

  created() {
    this.$store.commit("setSelectedTab", "adminProjects");

    this.loadProjects();
  },

  methods: {
    async loadProjects() {
      try {
        this.projects = await api.projects.getAll();
      } catch (error) {
        this.$store.dispatch("errorSnack", error?.response?.data?.message);
      }
    },
  },
};
</script>
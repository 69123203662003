import store from "../store";
import axios from "axios";
import auth from "./authRequests";
import clients from "./clientRequests";
import propertyInvestments from "./propertyInvestmentRequests";
import stagedInvestments from "./stagedInvestmentRequests";
import properties from "./propertyRequests";
import blocks from "./blockRequests";
import projects from "./projectRequests";
import payments from "./paymentRequests";
import investmentValues from "./investmentValueRequests";
import posts from "./postRequests";
import opportunities from "./opportunityRequests";
import resources from "./resourceRequests";

const apiUrl = process.env.VUE_APP_API_URL;
const apiVersion = process.env.VUE_APP_API_VERSION;

const apiClient = axios.create({
  baseURL: `${apiUrl}/${apiVersion}`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiClient.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    store.dispatch("logout");
  }

  return Promise.reject(error);
});

auth.setClient(apiClient);
clients.setClient(apiClient);
propertyInvestments.setClient(apiClient);
stagedInvestments.setClient(apiClient);
properties.setClient(apiClient);
blocks.setClient(apiClient);
projects.setClient(apiClient);
payments.setClient(apiClient);
investmentValues.setClient(apiClient);
posts.setClient(apiClient);
opportunities.setClient(apiClient);
resources.setClient(apiClient);

export default {
  auth,
  clients,
  propertyInvestments,
  stagedInvestments,
  properties,
  blocks,
  projects,
  payments,
  investmentValues,
  posts,
  opportunities,
  resources
};
